<div class="d-flex p-relative">
  <aside class="sidebar" [ngClass]="{'opened': opened }">
    <span class="extended">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
    </span>
    <span class="search">
      <form novalidate>
        <svg-icon class="svg" src="assets/images/search.svg"></svg-icon>
        <input type="text" placeholder="Buscar" #search
               (keyup)="onKey(search.value)" />
      </form>
    </span>
    <div class="sidebar__scroll">
      <div class="accordion_item_notfound d-none text-white">
        Nenhum resultado encontrado para <strong class="text-uppercase"></strong>, tente outro termo.
      </div>
      <ol class="sidebar__first js-filter-sidebar" id="accordionPrimary">
        <li *ngFor="let a of materials; let i = index;" class="js-filter-sidebar-item">
          <a href="javascript:void('');" id="collapseItem{{i}}" (click)="collapseAction(i)" data-toggle="collapse">
            {{a.name}}
          </a>
          <div id="collapsePrimary{{i}}" class="collapse">
            <div>
              <ul class="sidebar__second">
                <li *ngFor="let sub of a?.content">
                  <a class="js-filter-sidebar-subitem" href="javascript:void('');" [attr.data-id]="a.id" id="collapseItemInternal{{a.toString()+sub.toString()}}"
                     (click)="onNavigator('/materiais-detalhe/' + a.id)">
                    <strong>{{sub.gri}}-{{sub.index}}</strong>
                    {{sub.title}}
                  </a>
                  <!--
                  <a type="button" id="collapseItemInternal{{a.toString()+sub.toString()}}"
                     (click)="collapseActionInternal(a.toString()+sub.toString())" data-toggle="collapse">
                    <strong>{{sub.code}}</strong><br />
                    {{sub.title}}
                  </a>
                  <div id="collapseOneIntern{{a.toString()+sub.toString()}}" class="collapse">
                    <ul class="sidebar__third">
                      <li><a
                        href="#"><strong>103-1</strong> Explicação sobre tema material e suas fronteiras</a></li>
                      <li><a href="#"><strong>103-2</strong> Abordagem de gestão e seus componentes</a></li>
                      <li><a href="#"><strong>103-3</strong> Avaliação da abordagem de gestão</a></li>
                      <li><a href="#"><strong>103-4</strong> Média de horas de treinamento por ano, por empregado,
                        discriminado por gênero e categoria funcional</a></li>
                    </ul>
                  </div>-->
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ol>
    </div>
  </aside>

  <div class="container" [ngClass]="{'has-sidebar': !opened }">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="javascript:void(0)" (click)="onNavigator('/')">Home</a></li>
        <li class="breadcrumb-item"><a href="javascript:void(0)" (click)="onNavigator('/materiais')">Temas Materiais</a>
        </li>
        <li class="breadcrumb-item active material_bread" aria-current="page">{{gri?.title}}</li>
      </ol>
    </nav>
    <section class="news">
      <h2 class="news_header">
        <span class="news__tag">
          <small>GRI</small>
          <span>{{gri?.gri}}-{{gri?.index}}</span>
        </span>
        <span class="news__title">{{gri?.title}}</span>
      </h2>
      <div class="news__description">
        <!--<p><strong>Cultura de Inovação</strong></p>-->
        <div [innerHTML]="gri?.content"></div>
      </div>
      <button class="news__button" (click)="openSidebar()">
        <svg-icon class="svg" src="assets/images/index.svg"></svg-icon>
      </button>
    </section>
  </div>

</div>
