<app-header></app-header>

<main class="main">
  <router-outlet></router-outlet>
</main>

<ng-container *ngIf="false">
  <h2 *ngFor="let x of users | async">{{x.name}}</h2>
  <fieldset>
    <div>
      <label>Nome</label>
      <input [(ngModel)]="modelUser.name" />
    </div>
    <div>
      <label>E-mail</label>
      <input [(ngModel)]="modelUser.email" />
    </div>
    <div>
      <label>Senha</label>
      <input [(ngModel)]="modelUser.password" />
    </div>
  </fieldset>
  <a href="javascript:void('');" class="btn btn-primary" (click)="onCreateUser()">Criar</a>
</ng-container>

<app-footer></app-footer>
