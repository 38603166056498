<div class="goals container" [ngClass]="{'has-sidebar': !opened }">
  <aside class="sidebar">
        <span class="search">
          <form novalidate>
            <svg-icon class="svg" src="assets/images/search.svg"></svg-icon>
            <input type="text" placeholder="Buscar" #search
                   (keyup)="onKey(search.value)" />
          </form>
        </span>
    <div class="sidebar__scroll">
      <div class="accordion_item_notfound d-none text-white"> Nenhum resultado encontrado para
        <strong class="text-uppercase"></strong>, tente outro termo.
      </div>
      <ol id="accordionPrimary" class="sidebar__first js-filter-sidebar">
        <ng-container *ngFor="let x of model; let i = index;">
          <li class="js-filter-sidebar-item">
            <a href="javascript:void('');" id="collapseItem{{i}}" (click)="collapseAction(i)" data-toggle="collapse">
              {{x.name}}
            </a>
            <div id="collapsePrimary{{i}}" class="collapse">
              <div>
                <ul class="sidebar__second">
                  <li *ngFor="let sub of x?.itens">
                    <a class="js-filter-sidebar-subitem" href="javascript:void('');" [attr.data-id]="sub.id" id="collapseItemInternal{{x.toString()+sub.toString()}}"
                       (click)="onNavigator('/metas-detalhe/' + sub.id)">
                      {{sub.title}}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ng-container>
        <!--<li class="js-filter-sidebar-item active">
          <a href="javascript:void('');"
             data-toggle="collapse" id="collapseItem0" class="active">Meio Ambiente</a>
          <div class="collapse show" id="collapsePrimary0" style="">
            <div>
              <ul class="sidebar__second">
                <li>
                  <a href="javascript:void('');"
                     class="js-filter-sidebar-subitem"
                     data-id="918e582b-5861-11ec-842c-0ac4c9bd84da">
                    Ser ainda mais Climate positive
                  </a>
                </li>
                <li>
                  <a href="javascript:void('');"
                     class="js-filter-sidebar-subitem"
                     data-id="918e5b50-5861-11ec-842c-0ac4c9bd84da">
                    Produtos de origem renovável</a>
                </li>
                <li>
                  <a href="javascript:void('');"
                     class="js-filter-sidebar-subitem"
                     data-id="918e5c19-5861-11ec-842c-0ac4c9bd84da">
                    Emissões</a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="js-filter-sidebar-item">
          <a href="javascript:void('');"
             data-toggle="collapse" id="collapseItem1"> Sociedade </a>
          <div class="collapse" id="collapsePrimary1">
            <div>
              <ul class="sidebar__second">
                <li>
                  <a href="javascript:void('');"
                     class="js-filter-sidebar-subitem"
                     data-id="91920a17-5861-11ec-842c-0ac4c9bd84da">
                    <strong>102-18</strong> Aspectos Gerais </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="js-filter-sidebar-item">
          <a href="javascript:void('');"
             data-toggle="collapse" id="collapseItem3">Negócios</a>
          <div class="collapse" id="collapsePrimary3" style="">
            <div>
              <ul class="sidebar__second">
                <li>
                  <a href="javascript:void('');"
                     class="js-filter-sidebar-subitem"
                     data-id="91920a17-5861-11ec-842c-0ac4c9bd84da">
                    <strong>102-18</strong> Aspectos Gerais </a>
                </li>
              </ul>
            </div>
          </div>
        </li>-->
      </ol>
    </div>
  </aside>
  <div class="row justify-content-end">
    <div class="col-12">
      <p class="goals_breadcrumbs">Home | Metas de Longo Prazo | <span>{{modelDetail.meta?.name}}</span></p>
      <div class="card px-3 py-5 bg-primary">
        <h1 class="goals_title">{{modelDetail.title}}</h1>
        <span class="goals_subtitle">{{modelDetail.description}}</span>
      </div>
      <div class="row mt-5" style="row-gap: 25px">
        <div class="col-sm-3">
          <h2 class="goals_title_secondary">Contexto</h2>
        </div>
        <div class="col-sm-9">
          <div [innerHTML]="modelDetail.context"></div>
        </div>
      </div>
      <div class="row mt-5" style="row-gap: 25px">
        <div class="col-sm-3">
          <h2 class="goals_title_secondary">Escopo</h2>
        </div>
        <div class="col-sm-9">
          <div [innerHTML]="modelDetail.scope"></div>
        </div>
      </div>
      <div class="row mt-5 py-4 card-dark" style="row-gap: 25px">
        <div class="col-sm-3">
          <h2 class="goals_title_secondary">Resultados em {{modelDetail.resultYear}}</h2>
        </div>
        <div class="col-sm-9">
          <div class="card-dark-body" [innerHTML]="modelDetail.result"></div>
        </div>
      </div>
      <div class="row py-4 card-dark card-dark-secondary" style="row-gap: 25px">
        <div class="col-sm-3">
          <h2 class="goals_title_secondary">Acompanhamento do KPI</h2>
        </div>
        <div class="col-sm-9">
          <div class="card-dark-body" [innerHTML]="modelDetail.kpi"></div>
        </div>
      </div>
      <div class="row my-5" style="row-gap: 25px">
        <div class="col-sm-3">
          <h2 class="goals_title_secondary">O que faremos em {{modelDetail.doingYear}}</h2>
        </div>
        <div class="col-sm-9">
          <div [innerHTML]="modelDetail.doing"></div>
        </div>
      </div>
    </div>
    <button class="news__button d-none" (click)="openSidebar()">
      <svg-icon class="svg" src="assets/images/index.svg"></svg-icon>
    </button>
  </div>
</div>
