import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-ods',
  templateUrl: './ods.component.html',
  styleUrls: ['./ods.component.scss']
})
export class OdsComponent  extends BaseComponent  implements OnInit {
  listSelect: any[] = [
    {id: 1, status: true},
    {id: 2, status: true},
    {
      id: 3, status: false,
      text: this.translate.instant('ods1')
    },
    {
      id: 4, status: false,
      text: this.translate.instant('ods2')
    },
    {id: 5, status: true},
    {id: 6, status: true},
    {id: 7, status: true},
    {
      id: 8, status: false,
      text: this.translate.instant('ods3')
    },
    {id: 9, status: true},
    {id: 10, status: true},
    {id: 11, status: true},
    {
      id: 12, status: false,
      text: this.translate.instant('ods4')
    },
    {
      id: 13, status: false,
      text: this.translate.instant('ods5')
    },
    {id: 14, status: true},
    {id: 15, status: true},
    {id: 16, status: true},
    {id: 17, status: true},
  ];

  constructor(public router: Router,
              public translate: TranslateService) {
    super(router, translate);
  }

  ngOnInit(): void {
  }

  getCulture() {
    if (localStorage.getItem('culture') === 'pt-BR' ){
      return 'https://eurofarma.com.br/relatorio-anual';
    }
    else if (localStorage.getItem('culture') === 'en-US' ){
      return 'https://eurofarma.com.br/annual-report';
    }
    else  return 'https://eurofarma.com.br/informe-de-sustentabilidad';
  }

}
