import { Component, OnInit } from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Meta, MetaItem, MetaItemGQL, MetaItemQuery, MetasGQL, MetasQuery} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

declare var $: any;

@Component({
  selector: 'app-goals-detail',
  templateUrl: './goals-detail.component.html',
  styleUrls: ['./goals-detail.component.scss']
})
export class GoalsDetailComponent extends BaseComponent implements OnInit {


  opened = false;
  paramId = '';
  modelDetail: MetaItem = new MetaItem();
  modelDetailQuery: QueryRef<MetaItemQuery>;

  model: Meta[] = [];
  modelQuery: QueryRef<MetasQuery>;

  constructor(public router: Router,
              public route2: ActivatedRoute,
              public translate: TranslateService,
              public metasGQL: MetasGQL,
              public metaItemGQL: MetaItemGQL,
  ) {
    super(router, translate);
  }

  ngOnInit(): void {
    this.route2.params.subscribe(url => {
      if (!this.isNullOrUndefined(url)) {
        this.paramId = url.id;
        this.modelDetailQuery = this.metaItemGQL.watch({id: this.paramId});
        this.modelDetailQuery.valueChanges.subscribe(({data}) => {
          this.modelDetail = data.metaItem as MetaItem;
          setTimeout(() =>{
            this.handlerSideBarActive();
          });
        });
      }
    });
    this.getModel();
    $(function() {
      $('#temaMenu').addClass('active');
      $('.footer').find( '.container' ).addClass('footer_padding');
    });
  }

  getModel() {
    this.modelQuery = this.metasGQL.watch();
    this.modelQuery.valueChanges.subscribe(({data}) => {
      this.model = data.metas as Meta[];
    });
  }

  collapseAction(id) {
    console.log(id);
    $(`#collapsePrimary${id}`).collapse('toggle');
    $(`#collapseItem${id}`).toggleClass('active');
  }
  handlerSideBarActive() {
    $('.js-filter-sidebar-subitem').removeClass('active');
    const a = $('.js-filter-sidebar-subitem[data-id='+ this.paramId +']');
    a.addClass('active').closest('.collapse').parent().find('a:eq(0)').addClass('active');
    a.closest('.collapse').collapse('show');
    a.closest('.collapse').closest('.collapse').collapse('show');
    a.closest('.js-filter-sidebar-item').find('a:eq(0)').addClass('active');
  }

  openSidebar() {
    this.opened = !this.opened;
  }

}
