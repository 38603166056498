<header class="header">
  <div class="header__top">
    <div class="container">
      <a href="https://eurofarma.com.br" target="_blank" [innerHTML]="'accesseurosite'|translate"></a>
    </div>
  </div>
  <div class="header__middle">
    <div class="container">
      <div class="logo-header">
        <!--<h1 class="header__logo">
          <a href="/">Eurofarma</a>
        </h1>-->
        <a class="logo-header-link" href="/">
          <img src="assets/images/logo-header.png" alt="">
        </a>
      </div>
      <!--<h2 class="header__indicator">Indicadores <strong>2020</strong></h2>-->
      <div class="container-links">
        <!--<div class="container-links-route ">
          <a (click)="toggleMenu('/')" [ngClass]="{'active':router.url === '/'}">Home</a>
          <a (click)="toggleMenu('/Ambiental')" [ngClass]="{'active':router.url === '/Ambiental'}">{{"title2"|translate}}</a>
          <a (click)="toggleMenu('/Social')" [ngClass]="{'active':router.url === '/Social'}">Social | S</a>
          <a (click)="toggleMenu('/Governanca')" [ngClass]="{'active':router.url === '/Governanca'}">{{"title1"|translate}}</a>
          <a (click)="toggleMenu('/ods')" [ngClass]="{'active':router.url === '/ods'}">ODS</a>
          <a (click)="toggleMenu('/ods')" [ngClass]="{'active':router.url === '/ods'}">Relatório de Sustentabilidade</a>
        </div>-->
        <div class="container-links-route navigations" [ngClass]="{'open': opened }">
          <a (click)="toggleMenu('/')" [ngClass]="{'active':router.url === '/'}">Home</a>
          <a (click)="toggleMenu('/Ambiental')" [ngClass]="{'active':router.url === '/Ambiental'}">{{"title2"|translate}}</a>
          <a (click)="toggleMenu('/Social')" [ngClass]="{'active':router.url === '/Social'}">Social | S</a>
          <a (click)="toggleMenu('/Governanca')" [ngClass]="{'active':router.url === '/Governanca'}">{{"title1"|translate}}</a>
          <a (click)="toggleMenu('/ods')" [ngClass]="{'active':router.url === '/ods'}">{{"title3"|translate}}</a>
          <a href="https://eurofarma.com.br/relatorio-anual" target="_blank"
             [ngClass]="{'active':router.url === '/ods'}"> {{'sustentabilityreport'|translate}}</a>
          <a>
            <div class="header_options_item">
              <span>{{ 'idiom' | translate }}</span>
              <ul class="header_options_item_float">
                <li><a (click)="switchLanguage('pt-BR')">PT</a></li>
                <li><a (click)="switchLanguage('es-ES')">ES</a></li>
                <li><a (click)="switchLanguage('en-US')">EN</a></li>
              </ul>
            </div>
          </a>
          <!--<a (click)="toggleMenu('/ods')" [ngClass]="{'active':router.url === '/ods'}">Relatório de Sustentabilidade</a>-->
        </div>
        <!--<a class="header__download" href="#">
          <svg-icon class="svg" src="assets/images/download.svg"></svg-icon>
          <span>Relatório de <span>Sustentabilidade</span></span>
        </a>-->
        <div id="hamburger" (click)="toggleMenu()" [ngClass]="{'open': opened }">
          <svg width="100" height="100" viewBox="0 0 100 100">
            <path class="line line1"
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"/>
            <path class="line line2" d="M 20,50 H 80"/>
            <path class="line line3"
                  d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <ul *ngIf="false" class="navigation" [ngClass]="{'open': opened }">
    <li><a (click)="toggleMenu('/')" [ngClass]="{'active':router.url === '/'}">Home</a></li>
    <li><a (click)="toggleMenu('/Ambiental')" [ngClass]="{'active':router.url === '/Ambiental'}">{{"title2"|translate}}</a></li>
    <li><a (click)="toggleMenu('/Social')" [ngClass]="{'active':router.url === '/Social'}">Social | S</a></li>
    <li><a (click)="toggleMenu('/Governanca')" [ngClass]="{'active':router.url === '/Governanca'}">{{"title1"|translate}}</a>
    </li>
    <li><a (click)="toggleMenu('/ods')" [ngClass]="{'active':router.url === '/ods'}">{{"title3"|translate}}</a></li>


    <!--<li><a id="temaMenu" (click)="toggleMenu('/materiais')"
           [ngClass]="{'active':router.url === '/materiais'||router.url === '/materiais-detalhe'}">Tema
      Materiais</a></li>
    <li><a id="griMenu" (click)="toggleMenu('/gri')" [ngClass]="{'active':router.url === '/gri'}">Global Reporting
      Initiative</a></li>
    <li>
      <a id="metas" (click)="toggleMenu('/metas')" [ngClass]="{'active':router.url === '/metas'}">
      Metas de Longo Prazo
    </a>
    </li>
    <li><a (click)="toggleMenu('/ods')" [ngClass]="{'active':router.url === '/ods'}">ODS</a></li>
    <li><a (click)="toggleMenu('/contato')">Fale Conosco</a></li>-->
  </ul>


</header>
