import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {QueryRef} from "apollo-angular";
import {
  GetGlobalReportingItemGQL,
  GetGlobalReportingItemQuery,
  GetMaterialsGQL,
  GetMaterialsQuery, Theme, ThemeGQL, ThemeQuery, ThemesGQL, ThemesQuery
} from "../../../generated/graphql";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";

declare var $;

@Component({
  selector: 'app-materiais-detalhe',
  templateUrl: './materiais-detalhe.component.html',
  styleUrls: ['./materiais-detalhe.component.scss']
})
export class MateriaisDetalheComponent extends BaseComponent implements OnInit, OnDestroy {
  opened = false;
  paramId = null;
  gri: any; // Observable<Query['getChallenges']>;
  griQuery: QueryRef<ThemeQuery>;

  materials: Theme[] = [];
  materialsQuery: QueryRef<ThemesQuery>;

  modelSidebar: any = {};

  constructor(public router: Router,
              public route2: ActivatedRoute,
              public userService: UserService,
              private getGlobalReportingItemGQL: GetGlobalReportingItemGQL,
              private getMaterialsGQL: GetMaterialsGQL,
              private themeGQL: ThemeGQL,
              private themesGQL: ThemesGQL,
              public translate: TranslateService
              ) {
    super(router, translate);
  }

  ngOnInit(): void {
    // tslint:disable-next-line:only-arrow-functions
    $(function() {
      $('#temaMenu').addClass('active');
      $('.footer').find( '.container' ).addClass('footer_padding');
    });
    /*this.userService.sidebarData.subscribe({
      next: data => {
        const a: any = data;
        this.modelSidebar = a;
      }, error: err => super.onError(err)
    });*/
  /*  $(function() {
      $('.footer').addClass('space');
    });*/

    this.materialsQuery = this.themesGQL.watch();
    this.materialsQuery.valueChanges.subscribe(({data}) => {
      this.materials = data.themes;
    });

    this.route2.params.subscribe(url => {
      if (!this.isNullOrUndefined(url)) {
        this.paramId = url.id;
        this.griQuery = this.themeGQL.watch({id: url.id});
        this.griQuery.valueChanges.subscribe(({data}) => {
          this.gri = data.theme.content[0];
          setTimeout(() =>{
            this.handlerSideBarActive();
          });
        });
      }
    });
  }

  ngOnDestroy(): void {
    $('.footer').find( '.container' ).removeClass('footer_padding');
    $('#temaMenu').removeClass('active');
  }

  handlerSideBarActive() {
    $('.js-filter-sidebar-subitem').removeClass('active');
    const a = $('.js-filter-sidebar-subitem[data-id='+ this.paramId +']');
    a.addClass('active').closest('.collapse').parent().find('a:eq(0)').addClass('active');
    a.closest('.collapse').collapse('show');
  }

  openSidebar() {
    this.opened = !this.opened;
  }

  collapseAction(id) {
    console.log(id);
    $(`#collapsePrimary${id}`).collapse('toggle');
    $(`#collapseItem${id}`).toggleClass('active');
  }

  collapseActionInternal(sub) {
    $(`#collapseOneIntern${sub}`).collapse('toggle');
    $(`#collapseItemInternal${sub}`).toggleClass('active');
  }

  onKey(value: string) {
    const fieldValue = value;
    let numShow = 0;
    $('.js-filter-sidebar').find('.js-filter-sidebar-item').each(function(e, i) {
      console.log($(this).text().toLowerCase());
      if ($(this).text().toLowerCase().indexOf(fieldValue.toLowerCase()) >= 0) {
        $(this).removeClass('d-none');
        numShow++;

        let numShowSub = 0;
        $(this).find('li').each(function(e, i) {
          if ($(this).text().toLowerCase().indexOf(fieldValue.toLowerCase()) >= 0) {
            $(this).removeClass('d-none');
            numShowSub++;
          } else {
            $(this).addClass('d-none');
          }
        });

      } else {
        $(this).addClass('d-none');
      }
    });
    if (numShow === 0) {
      $('.accordion_item_notfound').find('strong').html(fieldValue);
      $('.accordion_item_notfound').removeClass('d-none');
    } else {
      $('.accordion_item_notfound').find('strong').html('');
      $('.accordion_item_notfound').addClass('d-none');
    }
  }
}
