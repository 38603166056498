<div class="container mt-5">
  <p>Home | Metas de Longo Prazo</p>
  <div class="row">
    <div class="col-12 col-md-7">
      <h1 class="page__title">Metas <br><span class="page__title2">de Longo Prazo</span> </h1>
      <p>
        Diante da complexidade dos desafios e das oportunidades existentes, a Eurofarma construiu sua Estratégia de
        Sustentabilidade de forma colaborativa, considerando a multiplicidade de visões de seus públicos de
        relacionamento.
      </p>
      <p>
        Assumimos o compromisso de ampliar nosso papel na cadeia de valor e na sociedade por meio de metas de longo
        prazo,
        norteando nosso caminho ao longo dos próximos dez anos, período que a Organização das Nações Unidas (ONU) chama
        de
        Década da Ação.
      </p>
    </div>
    <div class="col-12 col-md-5">
      <ol id="accordionPrimary">
        <ng-container *ngFor="let x of model; let i = index;">
          <li>
            <a type="button" class="accordion accordion_{{i}}" id="item_{{i}}"
               [style]="{'background-color': x.color}"
               (click)="collapseAction(i)" data-toggle="collapse">
              {{x.name}}
              <svg-icon class="icon" src="assets/svg/arrowup.svg"></svg-icon>
            </a>
            <div id="collapse_{{i}}" class="collapse">
              <div>
                <ul>
                  <li class="list-none" *ngFor="let c of x.itens">
                    <a class="text_link" href="javascript:void(0);" routerLink="/metas-detalhe/{{c.id}}">{{c.title}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ng-container>
        <!--<li>
          <a type="button" class="accordion_1" id="item_one" (click)="collapseAction('one')" data-toggle="collapse">
            Meio Ambiente
            <svg-icon class="icon" src="assets/svg/arrowup.svg"></svg-icon>
          </a>
          <div id="collapse_one" class="collapse">
            <div>
              <ul>
                <li class="list-none">
                  <a class="text_link" href="javascript:void(0)" routerLink="/metas-detalhe/1">Ser ainda mais Climate positive</a>
                </li>
                <li class="list-none" routerLink="metas-detalhe/1">
                  <a class="text_link" href="javascript:void(0)" routerLink="/metas-detalhe/1">Produtos de origem renovável</a>
                </li>
                <li class="list-none" routerLink="metas-detalhe/1">
                  <a class="text_link" href="javascript:void(0)" routerLink="/metas-detalhe/1">Emissões</a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <a type="button" class="accordion_2" id="item_two" (click)="collapseAction('two')" data-toggle="collapse">
            Sociedade
            <svg-icon class="icon" src="assets/svg/arrowup.svg"></svg-icon>
          </a>
          <div id="collapse_two" class="collapse">
            <div>
              <ul>
                <li class="list-none">
                  <a class="text_link" href="javascript:void(0)" routerLink="/metas-detalhe/1">Produtos de origem renovável</a>
                </li>
                <li class="list-none">
                  <a class="text_link" href="javascript:void(0)" routerLink="/metas-detalhe/1">Emissões</a>
                </li>
                <li class="list-none">
                  <a class="text_link" href="javascript:void(0)" routerLink="/metas-detalhe/1">Conservação da biodiversidade</a>
                </li>
              </ul>
            </div>
          </div>

        </li>
        <li>
          <a type="button" class="accordion_3" id="item_tree" (click)="collapseAction('tree')" data-toggle="collapse">
            Negócios
            <svg-icon class="icon" src="assets/svg/arrowup.svg"></svg-icon>
          </a>
          <div id="collapse_tree" class="collapse">
            <div>
              <ul>
                <li class="list-none">
                  <a class="text_link" href="javascript:void(0)" routerLink="/metas-detalhe/1">Produtos de origem renovável</a>
                </li>
                <li class="list-none">
                  <a class="text_link" href="javascript:void(0)" routerLink="/metas-detalhe/1">Emissões</a>
                </li>
                <li class="list-none">
                  <a class="text_link" href="javascript:void(0)" routerLink="/metas-detalhe/1">Conservação da biodiversidade</a>
                </li>
              </ul>
            </div>
          </div>
        </li>-->
      </ol>
    </div>
  </div>
</div>
