import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {QueryRef} from "apollo-angular";
import {
  GetBannersGQL,
  GetHighlightsQuery,
  GetMaterialsGQL,
  GetMaterialsQuery, Theme,
  ThemesGQL, ThemesQuery
} from "../../../generated/graphql";
import {TranslateService} from "@ngx-translate/core";

declare var  $;

@Component({
  selector: 'app-materiais',
  templateUrl: './materiais.component.html',
  styleUrls: ['./materiais.component.scss']
})
export class MateriaisComponent extends BaseComponent implements OnInit, OnDestroy {

  materials: Theme[] = [];
  materialsQuery: QueryRef<ThemesQuery>;

  modelFiltered: any = {};

  constructor(public router: Router,
              private getMaterialsGQL: GetMaterialsGQL,
              private themesGQL: ThemesGQL,
              public translate: TranslateService
              ) {
    super(router, translate);
  }

  ngOnInit(): void {
    this.getModel();
  }

  ngOnDestroy(): void {
    super.destroyModal('filter');
  }

  getModel() {
    this.materialsQuery = this.themesGQL.watch();
    this.materialsQuery.valueChanges.subscribe(({data}) => {
      this.materials = data.themes as Theme[];
    });
  }

  onNavigator(s: string) {
    $('html, body').stop().animate({ scrollTop: 0 }, 1000);
    this.router.navigate([s]);
  }

  onMaterialDetail(a: any) {
    this.modelFiltered = Object.assign({}, a);
    $('#filter').modal('show');
  }

  onKey(value: string) {
    const fieldValue = value;
    let numShow = 0;
    $('.js-filter-list').find('.js-filter-list-item').each(function(e, i) {
      // console.log($(this).find('.name').text());
      if ($(this).find('a').text().toLowerCase().indexOf(fieldValue.toLowerCase()) >= 0) {
        $(this).removeClass('d-none');
        numShow++;
      } else {
        $(this).addClass('d-none');
      }
    });
    if (numShow === 0) {
      $('.accordion_item_notfound').find('strong').html(fieldValue);
      $('.accordion_item_notfound').removeClass('d-none');
    } else {
      $('.accordion_item_notfound').find('strong').html('');
      $('.accordion_item_notfound').addClass('d-none');
    }
  }
}
