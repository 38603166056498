import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export class AuthInput {
  code: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  redirectUri: Scalars['String'];
};

export class AuthType {
  token: Scalars['String'];
  user: User;
};

export class Banner {
  bannerUrl?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  language: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export class Contact {
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  message: Scalars['String'];
  name: Scalars['String'];
  subject: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class Content {
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  gri: Scalars['String'];
  griGroup?: Maybe<Array<GriGroup>>;
  id: Scalars['String'];
  index: Scalars['String'];
  ods?: Maybe<Array<Ods>>;
  pag: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
  theme?: Maybe<Array<Theme>>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export class ContentPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<Content>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class CreateBannerInput {
  description: Scalars['String'];
  /** Enum pt-BR|es-ES */
  language: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export class CreateContactInput {
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  subject: Scalars['String'];
};

export class CreateContentInput {
  content: Scalars['String'];
  gri: Scalars['String'];
  index: Scalars['String'];
  ods?: InputMaybe<Array<CreateContentOdsInput>>;
  pag: Scalars['String'];
  title: Scalars['String'];
  /** Enum "material"|"gri" */
  type: Scalars['String'];
};

export class CreateContentOdsInput {
  id: Scalars['String'];
};

export class CreateGlobalReportingInput {
  code?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fatherId?: InputMaybe<Scalars['String']>;
  /** Enum pt-BR|es-ES */
  language: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  /** Enum material|global */
  type: Scalars['String'];
};

export class CreateGlobalReportingItemInput {
  GlobalReportingId: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export class CreateGriGroupContentInput {
  id: Scalars['String'];
};

export class CreateGriGroupInput {
  content?: InputMaybe<Array<CreateGriGroupContentInput>>;
  name: Scalars['String'];
};

export class CreateGriInput {
  color: Scalars['String'];
  description: Scalars['String'];
  groups?: InputMaybe<Array<CreateGriGroupInput>>;
  title: Scalars['String'];
};

export class CreateHighlightItemHighlightInput {
  id: Scalars['String'];
};

export class CreateHighlightItemInput {
  description: Scalars['String'];
  highlight?: InputMaybe<CreateHighlightItemHighlightInput>;
  title: Scalars['String'];
};

export class CreateMetaInput {
  color: Scalars['String'];
  name: Scalars['String'];
};

export class CreateMetaItemInput {
  context?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  doing?: InputMaybe<Scalars['String']>;
  doingYear?: InputMaybe<Scalars['Float']>;
  kpi?: InputMaybe<Scalars['String']>;
  meta: CreateMetaItemMetaInput;
  result?: InputMaybe<Scalars['String']>;
  resultYear?: InputMaybe<Scalars['Float']>;
  scope?: InputMaybe<Scalars['String']>;
  /** True for publish false for save */
  status?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export class CreateMetaItemMetaInput {
  id: Scalars['String'];
};

export class CreateOdInput {
  color: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['Float'];
};

export class CreateThemeContentInput {
  id: Scalars['String'];
};

export class CreateThemeInput {
  content: Array<CreateThemeContentInput>;
  description: Scalars['String'];
  name: Scalars['String'];
};

export class CreateUserInput {
  email: Scalars['String'];
  name: Scalars['String'];
};

export class DefaultMessage {
  message: Scalars['String'];
  status: Scalars['Int'];
};

export class GlobalReporting {
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  father?: Maybe<GlobalReporting>;
  globalReportingItem?: Maybe<Array<GlobalReportingItem>>;
  id: Scalars['ID'];
  language: Scalars['String'];
  sons?: Maybe<Array<GlobalReporting>>;
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export class GlobalReportingItem {
  code?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  globalReporting: GlobalReporting;
  globalReportingItemImages?: Maybe<Array<GlobalReportingItemImage>>;
  id: Scalars['ID'];
  page?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export class GlobalReportingItemImage {
  id: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
};

export class Gri {
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<GriGroup>>;
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class GriGroup {
  content?: Maybe<Array<Content>>;
  createdAt: Scalars['DateTime'];
  gri?: Maybe<Gri>;
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class GriPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<Gri>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Highlight {
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  itens?: Maybe<Array<HighlightItem>>;
  name?: Maybe<Scalars['String']>;
};

export class HighlightItem {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  highlight?: Maybe<Highlight>;
  id: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class Meta {
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  itens?: Maybe<Array<MetaItem>>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class MetaItem {
  context?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  doing?: Maybe<Scalars['String']>;
  doingYear?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  kpi?: Maybe<Scalars['String']>;
  meta?: Maybe<Meta>;
  result?: Maybe<Scalars['String']>;
  resultYear?: Maybe<Scalars['Float']>;
  scope?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export class MetaItemPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<MetaItem>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Mutation {
  /** Create new banner */
  createBanner: Banner;
  /** Create new contact */
  createContact: Contact;
  createContent: Content;
  /** create new global report */
  createGLobalReport: GlobalReporting;
  /** Create new global report item */
  createGlobalreportItem: GlobalReportingItem;
  createGri: Gri;
  createHighlight: HighlightItem;
  createMeta: Meta;
  createMetaItem: MetaItem;
  /** Create new Ods */
  createOds: Ods;
  createTheme: Theme;
  /** Create new user */
  createUser: User;
  login: AuthType;
  /** Remove banner by id */
  removeBanner: DefaultMessage;
  /** Delete contacts by id */
  removeContact: Scalars['Boolean'];
  removeContent: DefaultMessage;
  /** delete global report by id */
  removeGlobalReport: Scalars['Boolean'];
  /** Remove global report item by id */
  removeGlobalReportingItem: Scalars['Boolean'];
  removeGri: DefaultMessage;
  removeHighlight: DefaultMessage;
  removeMeta: DefaultMessage;
  removeMetaItem: DefaultMessage;
  /** Remove ods By id */
  removeOds: Ods;
  removeTheme: DefaultMessage;
  /** Remove user by id */
  removeUser: DefaultMessage;
  /** Update banner by id */
  updateBanner: Banner;
  /** Update contact */
  updateContact: Contact;
  updateContent: Content;
  /** Update global report by id */
  updateGlobalReport: GlobalReporting;
  /** Update global report */
  updateGlobalReportingItem: GlobalReportingItem;
  updateGri: Gri;
  updateHighlight: Highlight;
  updateMeta: Meta;
  updateMetaItem: MetaItem;
  /** Update ods */
  updateOds: Ods;
  updateTheme: Theme;
  /** update user */
  updateUser: User;
};


export class MutationCreateBannerArgs {
  data: CreateBannerInput;
};


export class MutationCreateContactArgs {
  data: CreateContactInput;
};


export class MutationCreateContentArgs {
  data: CreateContentInput;
};


export class MutationCreateGLobalReportArgs {
  data: CreateGlobalReportingInput;
};


export class MutationCreateGlobalreportItemArgs {
  data: CreateGlobalReportingItemInput;
};


export class MutationCreateGriArgs {
  data: CreateGriInput;
};


export class MutationCreateHighlightArgs {
  data: CreateHighlightItemInput;
};


export class MutationCreateMetaArgs {
  data: CreateMetaInput;
};


export class MutationCreateMetaItemArgs {
  data: CreateMetaItemInput;
};


export class MutationCreateOdsArgs {
  data: CreateOdInput;
};


export class MutationCreateThemeArgs {
  data: CreateThemeInput;
};


export class MutationCreateUserArgs {
  data: CreateUserInput;
};


export class MutationLoginArgs {
  data: AuthInput;
};


export class MutationRemoveBannerArgs {
  id: Scalars['String'];
};


export class MutationRemoveContactArgs {
  id: Scalars['String'];
};


export class MutationRemoveContentArgs {
  id: Scalars['String'];
};


export class MutationRemoveGlobalReportArgs {
  id: Scalars['String'];
};


export class MutationRemoveGlobalReportingItemArgs {
  id: Scalars['String'];
};


export class MutationRemoveGriArgs {
  id: Scalars['String'];
};


export class MutationRemoveHighlightArgs {
  id: Scalars['String'];
};


export class MutationRemoveMetaArgs {
  id: Scalars['String'];
};


export class MutationRemoveMetaItemArgs {
  id: Scalars['String'];
};


export class MutationRemoveOdsArgs {
  id: Scalars['String'];
};


export class MutationRemoveThemeArgs {
  id: Scalars['String'];
};


export class MutationRemoveUserArgs {
  id: Scalars['String'];
};


export class MutationUpdateBannerArgs {
  data: UpdateBannerInput;
};


export class MutationUpdateContactArgs {
  data: UpdateContactInput;
};


export class MutationUpdateContentArgs {
  data: UpdateContentInput;
};


export class MutationUpdateGlobalReportArgs {
  data: UpdateGlobalReportingInput;
};


export class MutationUpdateGlobalReportingItemArgs {
  data: UpdateGlobalReportingItemInput;
};


export class MutationUpdateGriArgs {
  data: UpdateGriInput;
};


export class MutationUpdateHighlightArgs {
  data: UpdateHighlightItemInput;
};


export class MutationUpdateMetaArgs {
  data: UpdateMetaInput;
};


export class MutationUpdateMetaItemArgs {
  data: UpdateMetaItemInput;
};


export class MutationUpdateOdsArgs {
  data: UpdateOdInput;
};


export class MutationUpdateThemeArgs {
  data: UpdateThemeInput;
};


export class MutationUpdateUserArgs {
  data: UpdateUserInput;
};

export class Ods {
  color: Scalars['String'];
  content?: Maybe<Array<Content>>;
  description: Scalars['String'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  number: Scalars['Float'];
};

export class Query {
  content: Content;
  contents: Array<Content>;
  contentsByType: Array<Content>;
  /** get me */
  currentUser: User;
  /** Return banner by id */
  getBanner: Banner;
  /** List all banners */
  getBanners: Array<Banner>;
  /** Return contact by id */
  getContact: Contact;
  /** List all contacts */
  getContacts: Array<Contact>;
  /** Return global report by id */
  getGlobalReport: GlobalReporting;
  /** Return global report item by id */
  getGlobalReportingItem: GlobalReportingItem;
  /** List all global report itens */
  getGlobalReportingItems: Array<GlobalReportingItem>;
  /** List all global reports */
  getGlobalReports: Array<GlobalReporting>;
  /** List all material themes */
  getMaterials: Array<GlobalReporting>;
  /** Return ods by id */
  getOds: Ods;
  /** List all odses */
  getOdses: Array<Ods>;
  /** Return user by id */
  getUser: User;
  /** Return user by email */
  getUserByEmail: User;
  /** List all users */
  getUsers: Array<User>;
  gri: Gri;
  griCodes: Array<Scalars['String']>;
  gris: Array<Gri>;
  highlight: Highlight;
  highlights: Array<Highlight>;
  meta: Meta;
  metaItem: MetaItem;
  metaItens: Array<MetaItem>;
  metas: Array<Meta>;
  searchContent: ContentPage;
  searchGri: GriPage;
  searchMetaItem: MetaItemPage;
  searchTheme: ThemePage;
  theme: Theme;
  themes: Array<Theme>;
  /** Return users by keyword */
  userByKeyword: UserPage;
};


export class QueryContentArgs {
  id: Scalars['String'];
};


export class QueryContentsByTypeArgs {
  code?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};


export class QueryGetBannerArgs {
  id: Scalars['String'];
};


export class QueryGetContactArgs {
  id: Scalars['String'];
};


export class QueryGetGlobalReportArgs {
  id: Scalars['String'];
};


export class QueryGetGlobalReportingItemArgs {
  id: Scalars['String'];
};


export class QueryGetOdsArgs {
  id: Scalars['String'];
};


export class QueryGetUserArgs {
  id: Scalars['String'];
};


export class QueryGetUserByEmailArgs {
  email: Scalars['String'];
};


export class QueryGriArgs {
  id: Scalars['String'];
};


export class QueryHighlightArgs {
  id: Scalars['String'];
};


export class QueryMetaArgs {
  id: Scalars['String'];
};


export class QueryMetaItemArgs {
  id: Scalars['String'];
};


export class QueryMetaItensArgs {
  id: Scalars['String'];
};


export class QuerySearchContentArgs {
  data: SearchContentInput;
};


export class QuerySearchGriArgs {
  data: SearchGriInput;
};


export class QuerySearchMetaItemArgs {
  data: SearchMetaItemInput;
};


export class QuerySearchThemeArgs {
  data: SearchThemeInput;
};


export class QueryThemeArgs {
  id: Scalars['String'];
};


export class QueryUserByKeywordArgs {
  keyword: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
};

export class SearchContentInput {
  end: Scalars['DateTime'];
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  status?: InputMaybe<Scalars['Boolean']>;
  /** Enum 'material'|'gri' */
  type?: InputMaybe<Scalars['String']>;
};

export class SearchGriInput {
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};

export class SearchMetaItemInput {
  end: Scalars['DateTime'];
  group?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  /** True for publish false for save */
  status?: InputMaybe<Scalars['Boolean']>;
};

export class SearchThemeInput {
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};

export class Theme {
  content?: Maybe<Array<Content>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class ThemePage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<Theme>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UpdateBannerInput {
  bannerUrl: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** Enum pt-BR|es-ES */
  language?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export class UpdateContactInput {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

export class UpdateContentInput {
  content?: InputMaybe<Scalars['String']>;
  gri?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  index?: InputMaybe<Scalars['String']>;
  ods?: InputMaybe<Array<CreateContentOdsInput>>;
  pag?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  /** Enum "material"|"gri" */
  type?: InputMaybe<Scalars['String']>;
};

export class UpdateGlobalReportingInput {
  code?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fatherId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** Enum pt-BR|es-ES */
  language?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  /** Enum material|global */
  type?: InputMaybe<Scalars['String']>;
};

export class UpdateGlobalReportingItemInput {
  GlobalReportingId?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  page?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateGriInput {
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Array<CreateGriGroupInput>>;
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateHighlightItemInput {
  description?: InputMaybe<Scalars['String']>;
  highlight?: InputMaybe<CreateHighlightItemHighlightInput>;
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateMetaInput {
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export class UpdateMetaItemInput {
  context?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  doing?: InputMaybe<Scalars['String']>;
  doingYear?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  kpi?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<CreateMetaItemMetaInput>;
  result?: InputMaybe<Scalars['String']>;
  resultYear?: InputMaybe<Scalars['Float']>;
  scope?: InputMaybe<Scalars['String']>;
  /** True for publish false for save */
  status?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateOdInput {
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  descrption?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Float']>;
};

export class UpdateThemeInput {
  content?: InputMaybe<Array<CreateThemeContentInput>>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export class UpdateUserInput {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export class User {
  content?: Maybe<Array<Content>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metaItem?: Maybe<Array<MetaItem>>;
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class UserPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<User>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type UserFragment = { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined };

export type MetaFragment = { id: string, name: string, color?: string | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, itens?: Array<{ id: string, title: string, status: boolean, context?: string | null | undefined, scope?: string | null | undefined, result?: string | null | undefined, resultYear?: number | null | undefined, kpi?: string | null | undefined, doing?: string | null | undefined, doingYear?: number | null | undefined, description: string, createdAt: any, updatedAt: any, meta?: { id: string, name: string, color?: string | null | undefined } | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined }> | null | undefined };

export type MetaItemFragment = { id: string, title: string, status: boolean, context?: string | null | undefined, scope?: string | null | undefined, result?: string | null | undefined, resultYear?: number | null | undefined, kpi?: string | null | undefined, doing?: string | null | undefined, doingYear?: number | null | undefined, description: string, createdAt: any, updatedAt: any, meta?: { id: string, name: string, color?: string | null | undefined } | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined };

export type GriFragment = { id: string, title?: string | null | undefined, description?: string | null | undefined, color?: string | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined, groups?: Array<{ id: string, name: string, createdAt: any, updatedAt: any, gri?: { id: string, title?: string | null | undefined, description?: string | null | undefined, color?: string | null | undefined } | null | undefined, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, ods?: Array<{ id: string, isActive?: boolean | null | undefined, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined }> | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined }> | null | undefined }> | null | undefined };

export type GriGroupFragment = { id: string, name: string, createdAt: any, updatedAt: any, gri?: { id: string, title?: string | null | undefined, description?: string | null | undefined, color?: string | null | undefined } | null | undefined, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, ods?: Array<{ id: string, isActive?: boolean | null | undefined, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined }> | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined }> | null | undefined };

export type ContentFragment = { id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, ods?: Array<{ id: string, isActive?: boolean | null | undefined, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined }> | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined };

export type OdsFragment = { id: string, isActive?: boolean | null | undefined, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined };

export type ThemeFragment = { id: string, name: string, description: string, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, ods?: Array<{ id: string, isActive?: boolean | null | undefined, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined }> | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined }> | null | undefined };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { getUsers: Array<{ id: string, name?: string | null | undefined, email?: string | null | undefined }> };

export type CreateUserMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
}>;


export type CreateUserMutation = { createUser: { id: string, name?: string | null | undefined } };

export type GetHighlightsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHighlightsQuery = { highlights: Array<{ id: string, name?: string | null | undefined, icon?: string | null | undefined, color?: string | null | undefined, createdAt?: any | null | undefined, itens?: Array<{ title: string, description: string }> | null | undefined }> };

export type GetBannersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBannersQuery = { getBanners: Array<{ type: string, title: string, description: string, bannerUrl?: string | null | undefined }> };

export type GetMaterialsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMaterialsQuery = { getMaterials: Array<{ id: string, color?: string | null | undefined, type: string, code?: string | null | undefined, title?: string | null | undefined, description?: string | null | undefined, globalReportingItem?: Array<{ id: string, title?: string | null | undefined, description?: string | null | undefined, code?: string | null | undefined }> | null | undefined }> };

export type GetGlobalReportingItemQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetGlobalReportingItemQuery = { getGlobalReportingItem: { id: string, code?: string | null | undefined, title?: string | null | undefined, content?: string | null | undefined, page?: string | null | undefined, globalReporting: { id: string, title?: string | null | undefined }, globalReportingItemImages?: Array<{ url?: string | null | undefined }> | null | undefined } };

export type CreateContactMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  subject: Scalars['String'];
  message: Scalars['String'];
}>;


export type CreateContactMutation = { createContact: { id: string, name: string } };

export type GetGlobalReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGlobalReportsQuery = { getGlobalReports: Array<{ id: string, color?: string | null | undefined, language: string, type: string, code?: string | null | undefined, title?: string | null | undefined, sons?: Array<{ id: string, color?: string | null | undefined, language: string, type: string, code?: string | null | undefined, title?: string | null | undefined, globalReportingItem?: Array<{ id: string, code?: string | null | undefined, title?: string | null | undefined }> | null | undefined }> | null | undefined }> };

export type GrisQueryVariables = Exact<{ [key: string]: never; }>;


export type GrisQuery = { gris: Array<{ id: string, title?: string | null | undefined, description?: string | null | undefined, color?: string | null | undefined, createdAt?: any | null | undefined, updatedAt?: any | null | undefined, deletedAt?: any | null | undefined, groups?: Array<{ id: string, name: string, createdAt: any, updatedAt: any, gri?: { id: string, title?: string | null | undefined, description?: string | null | undefined, color?: string | null | undefined } | null | undefined, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, ods?: Array<{ id: string, isActive?: boolean | null | undefined, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined }> | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined }> | null | undefined }> | null | undefined }> };

export type ContentQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ContentQuery = { content: { id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, ods?: Array<{ id: string, isActive?: boolean | null | undefined, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined }> | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined } };

export type MetasQueryVariables = Exact<{ [key: string]: never; }>;


export type MetasQuery = { metas: Array<{ id: string, name: string, color?: string | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, itens?: Array<{ id: string, title: string, status: boolean, context?: string | null | undefined, scope?: string | null | undefined, result?: string | null | undefined, resultYear?: number | null | undefined, kpi?: string | null | undefined, doing?: string | null | undefined, doingYear?: number | null | undefined, description: string, createdAt: any, updatedAt: any, meta?: { id: string, name: string, color?: string | null | undefined } | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined }> | null | undefined }> };

export type MetaItemQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MetaItemQuery = { metaItem: { id: string, title: string, status: boolean, context?: string | null | undefined, scope?: string | null | undefined, result?: string | null | undefined, resultYear?: number | null | undefined, kpi?: string | null | undefined, doing?: string | null | undefined, doingYear?: number | null | undefined, description: string, createdAt: any, updatedAt: any, meta?: { id: string, name: string, color?: string | null | undefined } | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined } };

export type ThemesQueryVariables = Exact<{ [key: string]: never; }>;


export type ThemesQuery = { themes: Array<{ id: string, name: string, description: string, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, ods?: Array<{ id: string, isActive?: boolean | null | undefined, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined }> | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined }> | null | undefined }> };

export type ThemeQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ThemeQuery = { theme: { id: string, name: string, description: string, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, content?: Array<{ id: string, type?: string | null | undefined, gri: string, index: string, title: string, pag: string, content?: string | null | undefined, status?: boolean | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, ods?: Array<{ id: string, isActive?: boolean | null | undefined, number: number, name: string, description: string, color: string, imageUrl?: string | null | undefined }> | null | undefined, user?: { id: string, name?: string | null | undefined, email?: string | null | undefined, photo?: string | null | undefined } | null | undefined }> | null | undefined } };

export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  photo
}
    `;
export const MetaItemFragmentDoc = gql`
    fragment MetaItem on MetaItem {
  id
  title
  status
  context
  scope
  result
  resultYear
  kpi
  doing
  doingYear
  description
  createdAt
  updatedAt
  meta {
    id
    name
    color
  }
  user {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const MetaFragmentDoc = gql`
    fragment Meta on Meta {
  id
  name
  color
  createdAt
  updatedAt
  deletedAt
  itens {
    ...MetaItem
  }
}
    ${MetaItemFragmentDoc}`;
export const OdsFragmentDoc = gql`
    fragment Ods on Ods {
  id
  isActive
  number
  name
  description
  color
  imageUrl
}
    `;
export const ContentFragmentDoc = gql`
    fragment Content on Content {
  id
  type
  gri
  index
  title
  pag
  content
  status
  createdAt
  updatedAt
  deletedAt
  ods {
    ...Ods
  }
  user {
    ...User
  }
}
    ${OdsFragmentDoc}
${UserFragmentDoc}`;
export const GriGroupFragmentDoc = gql`
    fragment GriGroup on GriGroup {
  id
  name
  createdAt
  updatedAt
  gri {
    id
    title
    description
    color
  }
  content {
    ...Content
  }
}
    ${ContentFragmentDoc}`;
export const GriFragmentDoc = gql`
    fragment Gri on Gri {
  id
  title
  description
  color
  createdAt
  updatedAt
  deletedAt
  groups {
    ...GriGroup
  }
}
    ${GriGroupFragmentDoc}`;
export const ThemeFragmentDoc = gql`
    fragment Theme on Theme {
  id
  name
  description
  createdAt
  updatedAt
  deletedAt
  content {
    ...Content
  }
}
    ${ContentFragmentDoc}`;
export const GetUsersDocument = gql`
    query GetUsers {
  getUsers {
    id
    name
    email
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersGQL extends Apollo.Query<GetUsersQuery, GetUsersQueryVariables> {
    document = GetUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation CreateUser($name: String!, $email: String!) {
  createUser(data: {name: $name, email: $email}) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHighlightsDocument = gql`
    query GetHighlights {
  highlights {
    id
    name
    icon
    color
    createdAt
    itens {
      title
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHighlightsGQL extends Apollo.Query<GetHighlightsQuery, GetHighlightsQueryVariables> {
    document = GetHighlightsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBannersDocument = gql`
    query GetBanners {
  getBanners {
    type
    title
    description
    bannerUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBannersGQL extends Apollo.Query<GetBannersQuery, GetBannersQueryVariables> {
    document = GetBannersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMaterialsDocument = gql`
    query GetMaterials {
  getMaterials {
    id
    color
    type
    code
    title
    description
    globalReportingItem {
      id
      title
      description
      code
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMaterialsGQL extends Apollo.Query<GetMaterialsQuery, GetMaterialsQueryVariables> {
    document = GetMaterialsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGlobalReportingItemDocument = gql`
    query GetGlobalReportingItem($id: String!) {
  getGlobalReportingItem(id: $id) {
    id
    code
    title
    content
    page
    globalReporting {
      id
      title
    }
    globalReportingItemImages {
      url
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGlobalReportingItemGQL extends Apollo.Query<GetGlobalReportingItemQuery, GetGlobalReportingItemQueryVariables> {
    document = GetGlobalReportingItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateContactDocument = gql`
    mutation CreateContact($name: String!, $email: String!, $subject: String!, $message: String!) {
  createContact(
    data: {name: $name, email: $email, subject: $subject, message: $message}
  ) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateContactGQL extends Apollo.Mutation<CreateContactMutation, CreateContactMutationVariables> {
    document = CreateContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGlobalReportsDocument = gql`
    query GetGlobalReports {
  getGlobalReports {
    id
    color
    language
    type
    code
    title
    sons {
      id
      color
      language
      type
      code
      title
      globalReportingItem {
        id
        code
        title
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGlobalReportsGQL extends Apollo.Query<GetGlobalReportsQuery, GetGlobalReportsQueryVariables> {
    document = GetGlobalReportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GrisDocument = gql`
    query gris {
  gris {
    ...Gri
  }
}
    ${GriFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GrisGQL extends Apollo.Query<GrisQuery, GrisQueryVariables> {
    document = GrisDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContentDocument = gql`
    query content($id: String!) {
  content(id: $id) {
    ...Content
  }
}
    ${ContentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ContentGQL extends Apollo.Query<ContentQuery, ContentQueryVariables> {
    document = ContentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MetasDocument = gql`
    query metas {
  metas {
    ...Meta
  }
}
    ${MetaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MetasGQL extends Apollo.Query<MetasQuery, MetasQueryVariables> {
    document = MetasDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MetaItemDocument = gql`
    query metaItem($id: String!) {
  metaItem(id: $id) {
    ...MetaItem
  }
}
    ${MetaItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MetaItemGQL extends Apollo.Query<MetaItemQuery, MetaItemQueryVariables> {
    document = MetaItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ThemesDocument = gql`
    query Themes {
  themes {
    ...Theme
  }
}
    ${ThemeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ThemesGQL extends Apollo.Query<ThemesQuery, ThemesQueryVariables> {
    document = ThemesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ThemeDocument = gql`
    query theme($id: String!) {
  theme(id: $id) {
    ...Theme
  }
}
    ${ThemeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ThemeGQL extends Apollo.Query<ThemeQuery, ThemeQueryVariables> {
    document = ThemeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }