import {Component, OnDestroy, OnInit} from '@angular/core';
import {QueryRef} from "apollo-angular";
import {
  GetGlobalReportsGQL, Gri,
  GrisGQL, GrisQuery,
} from "../../../generated/graphql";
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

declare var  $;

@Component({
  selector: 'app-gri',
  templateUrl: './gri.component.html',
  styleUrls: ['./gri.component.scss']
})
export class GriComponent extends BaseComponent implements OnInit, OnDestroy {

  gris: Gri[] = [];
  grisQuery: QueryRef<GrisQuery>;

  modelFiltered: any = {};
  modelFilteredSon: any = {};

  constructor(public router: Router,
              private grisGQL: GrisGQL,
              public translate: TranslateService
              ) {
    super(router, translate);
  }

  ngOnInit() {
    this.grisQuery = this.grisGQL.watch();
    this.grisQuery.valueChanges.subscribe(({data}) => {
      this.gris = data.gris as Gri[];
    });
  }

  ngOnDestroy(): void {
    super.destroyModal('filterGRI');
  }

  onGriDetail(a: any, sub: any) {
    console.log('onGriDetail');
    console.log(a);
    console.log(sub);
    this.modelFiltered = Object.assign({}, a);
    this.modelFilteredSon = Object.assign({}, sub);
    $('#filterGRI').modal('show');
  }

  onNavigator(s: string) {
    $('html, body').stop().animate({scrollTop: 0}, 1000);
    this.router.navigate([s]);
  }

  onKey(value: string) {
    const fieldValue = value;
    let numShow = 0;
    $('.js-filter-list').find('.js-filter-list-item').each(function(e, i) {
      // console.log($(this).find('.name').text());
      if ($(this).find('a').text().toLowerCase().indexOf(fieldValue.toLowerCase()) >= 0) {
        $(this).removeClass('d-none');
        numShow++;
      } else {
        $(this).addClass('d-none');
      }
    });
    if (numShow === 0) {
      $('.accordion_item_notfound').find('strong').html(fieldValue);
      $('.accordion_item_notfound').removeClass('d-none');
    } else {
      $('.accordion_item_notfound').find('strong').html('');
      $('.accordion_item_notfound').addClass('d-none');
    }
  }

}
