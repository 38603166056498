import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';
import * as moment from 'moment';

declare var $: any;

export class BaseComponent {

  constructor(public router: Router,
              public translate: TranslateService) {
  }
  showMessage(t: string, m: string, type: any = null) {
    Swal.fire({
      title: t,
      html: m,
      icon: type,
      confirmButtonColor: '#265ba3',
    });
  }

  confirmMessage(t: string, m: string, callback: any = null) {
    Swal.fire({
      title: t,
      html: m,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#265ba3',
      cancelButtonColor: '#dc3545'
    }).then((result) => {
      if (!this.isNullOrUndefined(result.value) && result.value === true) {
        if (callback != null) {
          callback();
        }
      }
    });
  }

  isNullOrUndefined(value) {
    return typeof value === 'undefined' || value == null || value.length === 0;
  }

  destroyModal(idModal) {
    const elModalUser = $('#' + idModal);
    if (elModalUser[0]) {
      elModalUser.remove();
    }
    const backdrop = $('.modal-backdrop.show');
    if (backdrop[0]) {
      backdrop.remove();
    } else {
      $('body').removeClass('modal-open');
    }
  }


  onError(error) {
    // this.loading.hide();
    if (error.status === 401) {
      this.router.navigate(['/login']);
      return;
    }

    // const e = JSON.parse(error['error']);

    if (error.status === 400) {
      this.showMessage('Atenção', error.error.message, 'warning');
      return;
    }

    if (error.status === 500) {
      this.showMessage('Atenção', 'Servidor indisponível, tente novamente mais tarde.', 'warning');
      return;
    }
  }

  validateField(obj) {
    let strError = ``;
    obj.map((e) => {
      if (this.isNullOrUndefined(e.value)) {
        strError += `${e.text}`;
      }
    });
    return strError;
  }

  isEMailValid(strEmail) {
    const str = strEmail;
    const exp = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
    const testResult = exp.test(str);
    if (!testResult) {
      this.showMessage('Atenção', 'Informe um e-mail válido para prosseguir.', 'warning');
    }
    return testResult;
  }

  isCPFValid(strCPF) {
    let Soma;
    let Resto;
    Soma = 0;
    const strErrorTitle = 'Atenção';
    const strErrorDescription = 'Informe um CPF válido para prosseguir.';
    strCPF = strCPF.replace(/\D+/g, '');
    if (strCPF === '00000000000' || strCPF === '12112112155') {
      this.showMessage(strErrorTitle, strErrorDescription, 'warning');
      return false;
    }
    for (let i = 1; i <= 9; i++) {
      // tslint:disable-next-line:radix
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }
    Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }
    // tslint:disable-next-line:radix
    if (Resto !== parseInt(strCPF.substring(9, 10))) {
      this.showMessage(strErrorTitle, strErrorDescription, 'warning');
      return false;
    }
    Soma = 0;
    for (let i = 1; i <= 10; i++) {
      // tslint:disable-next-line:radix
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    }
    Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }
    // tslint:disable-next-line:radix
    if (Resto !== parseInt(strCPF.substring(10, 11))) {
      this.showMessage(strErrorTitle, strErrorDescription, 'warning');
      return false;
    }
    return true;
  }

  dateOfBirthDateValidate(p: any) {
    // p = (p instanceof Date) ? p : moment(p, 'DD/MM/yyyy').format('DD/MM/yyyy');
    console.log('dateOfBirthDateValidate');
    console.log(p)
    if (p.indexOf('/') >= 0 || p.indexOf('-') >= 0) {
      p = moment(p, 'DD/MM/yyyy').format('DD/MM/yyyy');
    } else if (!this.isNullOrUndefined(p) && p.indexOf('/') < 0) {
      p = moment(p).format('DD/MM/yyyy');
    }
    const dateFormatMin = moment().subtract(18, 'year');
    const dateFormatMax = moment().subtract(120, 'year');
    const dateFormatCurrent = moment();
    let valReturn = true;
    if (this.isNullOrUndefined(p) || p === 'Invalid date') {
      this.showMessage('Atenção', 'Preencha a data de nascimento corretamente.', 'warning');
      valReturn = false;
    } else if (!moment(p, 'DD/MM/YYYY', true).isValid()) {
      this.showMessage('Atenção', 'Preencha a data de nascimento corretamente.', 'warning');
      valReturn = false;
    } else if (moment(p, 'DD/MM/YYYY') > dateFormatCurrent) {
      this.showMessage('Atenção', 'Preencha a data de nascimento corretamente.', 'warning');
      valReturn = false;
    } else if (moment(p, 'DD/MM/YYYY') > dateFormatMin) {
      this.showMessage('Atenção', 'Não é permitido cadastro de menores de 18 anos.', 'warning');
      valReturn = false;
    } else if (moment(p, 'DD/MM/YYYY') < dateFormatMax) {
      this.showMessage('Atenção', 'Não é permitido cadastro de maiores de 120 anos.', 'warning');
      valReturn = false;
    }
    return valReturn;
  }

  toggleLoader(b: boolean): void {
    if (!b) {
      $('#loaderBox').fadeOut();
    } else {
      $('#loaderBox').fadeIn();
    }
  }

  onNavigator(s: string) {
    $('html, body').stop().animate({scrollTop: 0}, 1000);
    this.router.navigate([s]);
  }

  onKey(value: string) {
    const fieldValue = value;
    let numShow = 0;
    $('.js-filter-sidebar').find('.js-filter-sidebar-item').each(function (e, i) {
      console.log($(this).text().toLowerCase());
      if ($(this).text().toLowerCase().indexOf(fieldValue.toLowerCase()) >= 0) {
        $(this).removeClass('d-none');
        numShow++;
        let numShowSub = 0;
        $(this).find('li').each(function (e, i) {
          if ($(this).text().toLowerCase().indexOf(fieldValue.toLowerCase()) >= 0) {
            $(this).removeClass('d-none');
            numShowSub++;
          } else {
            $(this).addClass('d-none');
          }
        });
      } else {
        $(this).addClass('d-none');
      }
    });
    if (numShow === 0) {
      $('.accordion_item_notfound').find('strong').html(fieldValue);
      $('.accordion_item_notfound').removeClass('d-none');
    } else {
      $('.accordion_item_notfound').find('strong').html('');
      $('.accordion_item_notfound').addClass('d-none');
    }
  }

  switchLanguage(language: string) {
    // this.setCulture('pt-BR');
    if (language === 'en-US') {
      language = 'en';
      this.setCulture('en-US');
    } else if (language === 'es-ES') {
      language = 'es';
      this.setCulture('es-ES');
    } else if (language === 'pt-BR') {
      language = 'pt';
      this.setCulture('pt-BR');
    }
    this.translate.use(language);

  }

  setCulture(culture: string) {
    localStorage.setItem('culture', culture);
  }

}
