import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {Apollo, QueryRef} from "apollo-angular";
import {BaseComponent} from "../../base/base.component";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {GetBannersGQL, GetBannersQuery, GetHighlightsGQL, GetHighlightsQuery} from "../../../generated/graphql";

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit {

  banners: any;
  bannersQuery: QueryRef<GetBannersQuery>;

  highlights: any;
  highlightsQuery: QueryRef<GetHighlightsQuery>;

  highlightsNumbers = [];

  /*
  society1 = 0;
  society2 = 0;
  society3 = 0;
  */
  options = {
    decimalPlaces: 1,
    separator: '.',
    decimal: ',',
  };

  primarySlider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
  }

  secondarySlider = {
    slidesToShow: 3,
    slidesToScroll: 2,
    infinite: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }


  thirdSlider = {
    slidesToShow: 4,
    slidesToScroll: 2,
    infinite: true,
    dots: true,
    responsive: [
      {
        breakpoint: 881,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  /*  @ViewChild('society1El') society1El: ElementRef;
    @ViewChild('society2El') society2El: ElementRef;
    @ViewChild('society3El') society3El: ElementRef;*/

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    /*if (this.isNullOrUndefined(this.highlights)) { return; }
    if (this.scrollCalculation($('.js-counter-up')[0], $event)) {
      this.highlights.map((x, i) => {
        this.highlightsNumbers[i].number01 = x['number0' + (i+1)];
        this.highlightsNumbers[i].number02 = x['number0' + (i+1)];
        this.highlightsNumbers[i].number03 = x['number0' + (i+1)];
      });
    }*/

    // SOCIETY
    /*if (this.scrollCalculation(this.society1El)) {
      this.society1 = 14.2;
    }
    if (this.scrollCalculation(this.society2El)) {
      this.society2 = 15.7;
    }
    if (this.scrollCalculation(this.society3El)) {
      this.society3 = 24;
    }*/
  }

  scrollCalculation(rects, event): boolean {
    const rect = rects.getBoundingClientRect(); // rects.nativeElement.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;
    if (window.innerWidth < 980) {
      return true;
    } else {
      return (elemTop >= 0) && (elemBottom <= window.innerHeight);
    }
    // return isVisible ? count : 0;
  }


  constructor(public router: Router,
              private apollo: Apollo,
              private getBannersGQL: GetBannersGQL,
              private getHighlightsGQL: GetHighlightsGQL,
              public translate: TranslateService) {
    super(router, translate);
  }

  /*banners = [
    {
      title: 'CONEXÕES <span>HUMANAS</span>',
      description: 'TORNAR A SAÚDE ACESSÍVEL PARA QUE TODOS POSSAM VIVER MAIS E MELHOR'
    },
    {
      title: 'CONEXÕES <span>HUMANAS</span>',
      description: 'TORNAR A SAÚDE ACESSÍVEL PARA QUE TODOS POSSAM VIVER MAIS E MELHOR'
    },
    {
      title: 'CONEXÕES <span>HUMANAS</span>',
      description: 'TORNAR A SAÚDE ACESSÍVEL PARA QUE TODOS POSSAM VIVER MAIS E MELHOR'
    }
  ]*/

  ngOnInit(): void {
    /*
    this.bannersQuery = this.getBannersGQL.watch();
    this.bannersQuery.valueChanges.subscribe(({data}) => {
      this.banners = data.getBanners;
    });
    this.highlightsQuery = this.getHighlightsGQL.watch();
    this.highlightsQuery.valueChanges.subscribe(({data}) => {
      this.highlights = data.highlights;
      this.handlerHighlightsNumbers();
    });
    */
  }

  ngAfterViewInit() {
    /*let
      page = window.innerWidth,
      title = document.querySelector<HTMLElement>('.banners__title'),
      news = document.querySelector<HTMLElement>('.central .carousel .slick-list'),
      dots = document.querySelectorAll<HTMLElement>('.slick-dots')

    if (this.isNullOrUndefined(title)) { return; }
    if (page >= 991) {
      for (let i = 0; i < dots.length; i++) {
        dots[i].style.marginLeft = `${title.offsetLeft}px`
      }
      news.style.paddingLeft = `${title.offsetLeft - 25}px`
    }*/
  }

  handlerHighlightsNumbers(): void {
    for (let x of this.highlights) {
      this.highlightsNumbers.push({
        number01: 0,
        number02: 0,
        number03: 0
      });
      console.log('for aqui');
    }
  }

  getCulture() {
    if (localStorage.getItem('culture') === 'pt-BR' ){
      return 'assets/images/banner.png';
    }
    else if (localStorage.getItem('culture') === 'en-US' ){
      return 'assets/images/banner-en.png';
    }
    else  return 'assets/images/banner.png';
  }

  getCulture2() {
    if (localStorage.getItem('culture') === 'pt-BR' ){
      return 'assets/images/banner3.png';
    }
    else if (localStorage.getItem('culture') === 'en-US' ){
      return 'assets/images/banner3-en.png';
    }
    else return 'assets/images/banner3-es.png';
  }

  getCulture3() {
    if (localStorage.getItem('culture') === 'en-US' ){
      return 'assets/images/hero-en.png';
    }
    else return 'assets/images/hero.png';
  }

  getBackgroundImage(): string {
    return `url(${this.getCulture3()}) no-repeat center 100px`;
  }
}
