<footer class="footer">
  <div class="container d-none">
    <div class="row">
      <div class="col-12 col-lg-4">
        <ul class="navigation">
          <li><a [routerLinkActive]="['active']" [routerLink]="''">Home</a></li>
          <!--<li><a [routerLinkActive]="['active']" [routerLink]="'/materiais'">Tema Materiais</a></li>-->
          <!--<li><a [routerLinkActive]="['active']" [routerLink]="'/gri'">Global Reporting Initiative</a></li>-->
          <li><a (click)="toggleMenu('/Ambiental')" [ngClass]="{'active':router.url === '/Ambiental'}">{{"title2"|translate}}</a></li>
          <li><a (click)="toggleMenu('/Social')" [ngClass]="{'active':router.url === '/Social'}">Social | S</a></li>
          <li><a (click)="toggleMenu('/Governanca')" [ngClass]="{'active':router.url === '/Governanca'}">{{"title1"|translate}}</a></li>
          <li><a [routerLinkActive]="['active']" [routerLink]="'/ods'">ODS</a></li>
        </ul>
        <hr class="d-none d-lg-block">
        <a href="https://eurofarma.com.br/relatorio-anual" target="_blank" class="footer__button">
          <span>Relatório de Sustentabilidade</span>
          <svg-icon class="svg" src="assets/images/download.svg"></svg-icon>
        </a>
      </div>
      <div class="col-12 col-lg-8 order-first order-lg-last">
        <h2 class="footer__title">Contato</h2>
        <form class="row" novalidate>
          <div class="col-12 col-sm-6">
            <div class="input-material">
              <input id="name" class="form-control" type="text" name="name"
                     [(ngModel)]="modelContact.name" required>
              <label for="name">Nome</label>
            </div>
            <div class="input-material">
              <input id="email" class="form-control" type="text" name="email"
                     [(ngModel)]="modelContact.email" required>
              <label for="email">E-mail</label>
            </div>
            <div class="input-material mb-lg-0">
              <input id="subject" class="form-control" type="text" name="subject"
                     [(ngModel)]="modelContact.subject" required>
              <label for="subject">Assunto</label>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="input-material">
              <textarea id="message" class="form-control" name="message"
                        [(ngModel)]="modelContact.message" required></textarea>
              <label for="message">Mensagem</label>
            </div>
            <button class="footer__send" (click)="onSendContact()">
              <span>Enviar</span>
              <svg class="svg" xmlns="http://www.w3.org/2000/svg" width="14.129" height="15.372" viewBox="0 0 14.129 15.372">
                <g transform="translate(12.629 13.25) rotate(180)">
                  <path d="M7.5,18H18.629" transform="translate(-7.5 -12.436)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  <path d="M18,7.5l5.564,5.564L18,18.629" transform="translate(-12.436 -7.5)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                </g>
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="footer__bottom">
    <div class="container">
      <p class="mb-0">© Eurofarma 2023</p>
      <span class="footer__copy">
        <a href="https://eurofarma.com.br/politica-de-privacidade" target="_blank">{{'privacypolicy'|translate}}</a>
      </span>
    </div>
  </div>
</footer>
