<div class="d-flex p-relative">
  <aside class="sidebar" [ngClass]="{'opened': opened }">
    <span class="extended">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
    </span>
    <span class="search">
      <form novalidate>
        <svg-icon class="svg" src="assets/images/search.svg"></svg-icon>
        <input type="text" placeholder="Buscar" #search
               (keyup)="onKey(search.value)" />
      </form>
    </span>
    <div class="sidebar__scroll">
      <div class="accordion_item_notfound d-none text-white">
        Nenhum resultado encontrado para <strong class="text-uppercase"></strong>, tente outro termo.
      </div>
      <ol class="sidebar__first js-filter-sidebar" id="accordionPrimary">
        <li *ngFor="let a of gris; let i = index;" class="js-filter-sidebar-item">
          <a type="button" id="collapseItem{{i}}" (click)="collapseAction(i)" data-toggle="collapse">
            {{a.title}}
          </a>
          <div id="collapsePrimary{{i}}" class="collapse">
            <div>
              <ul class="sidebar__second">
                <li *ngFor="let sub of a?.groups; let si = index;">
                  <a type="button" id="collapseItemInternal{{i+si}}"
                     (click)="collapseActionInternal(i+si)" data-toggle="collapse">
                    <strong>{{sub.gri}}</strong>
                    {{sub.name}}
                  </a>
                  <div id="collapseOneIntern{{i+si}}" class="collapse">
                    <ul class="sidebar__third">
                      <li *ngFor="let x of sub?.content">
                        <a class="js-filter-sidebar-subitem" href="javascript:void('');" [attr.data-id]="x.id" (click)="onNavigator('/gri-detalhe/' + x.id)">
                          <strong>{{x.gri}}-{{x.index}}</strong> {{x.title}}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!--<li *ngFor="let a of gris; let i = index;" class="js-filter-sidebar-item">
          <a type="button" id="collapseItem{{i}}" (click)="collapseAction(i)" data-toggle="collapse">
            {{a.title}}
          </a>
          <div id="collapsePrimary{{i}}" class="collapse">
            <div>
              <ul class="sidebar__second">
                <li *ngFor="let sub of a?.sons; let si = index;">
                  <a type="button" id="collapseItemInternal{{i+si}}"
                     (click)="collapseActionInternal(i+si)" data-toggle="collapse">
                    <strong>{{sub.code}}</strong>
                    {{sub.title}}
                  </a>
                  <div id="collapseOneIntern{{i+si}}" class="collapse">
                    <ul class="sidebar__third">
                      <li *ngFor="let x of sub?.globalReportingItem">
                        <a class="js-filter-sidebar-subitem" href="javascript:void('');" [attr.data-id]="x.id" (click)="onNavigator('/gri-detalhe/' + x.id)">
                          <strong>{{x.code}}</strong> {{x.title}}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>-->
      </ol>
    </div>
  </aside>

  <div class="container" [ngClass]="{'has-sidebar': !opened }">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="javascript:void(0)" (click)="onNavigator('/')">Home</a></li>
        <li class="breadcrumb-item"><a href="javascript:void(0)" (click)="onNavigator('/gri')">Global Reporting Initiative (GRI)</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{gri?.title}}</li>
      </ol>
    </nav>
    <section class="news">
      <h2 class="news_header">
        <span class="news__tag">
          <small>GRI</small>
          <span>{{gri?.gri}}-{{gri?.index}}</span>
        </span>
        <span class="news__title">{{gri?.title}}</span>
      </h2>

      <div class="ods_box">
        <b>ODS</b>
        <div class="ods_line">
            <div class="ods_image">
              <div class="ods_row">
                <ng-container *ngFor="let o of gri?.ods; let i = index">
                  <a class="ods"  title="{{o.name}}" *ngIf="o.isActive">
                    <div class="ods-bg" [style]="{'background':o.color}">
                    <div class="ods-span">{{o.name}}</div>

                    </div>
                    <div class="ods-info" >
                      <p class="number" [style]="{'background-color':o.color}">{{o.number}}</p>
                      <p class="number-bg" [style]="{'color':o.color}">{{o.number}}</p>
                      <!--<p class="name">{{o.name}} </p>-->
                    </div>
                    <svg-icon class="ods_svg" [svgStyle]="{'fill':o.color}"  src="assets/svg/ods/{{o.number}}.svg"></svg-icon>
                    <!--<img class="ods_card_img" src="{{o.imageUrl}}" alt="ods">-->
                  </a>
                </ng-container>
              </div>


            </div>
            <div class="ods_page">
              <b>PÁGINA DO RELATÓRIO</b>
              <span>Páginas {{gri?.pag}}</span>
            </div>
        </div>
      </div>
      <div class="news__description">
        <div [innerHTML]="gri?.content"></div>
      </div>
      <button class="news__button" (click)="openSidebar()">
        <svg-icon class="svg" src="assets/images/index.svg"></svg-icon>
      </button>
    </section>
  </div>

</div>
