import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router, ActivatedRoute} from '@angular/router';
import {CreateContactGQL, GetMaterialsGQL} from '../../../generated/graphql';
import {TranslateService} from "@ngx-translate/core";

declare var $;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {
  modelContact: any = {};

  constructor(public router: Router,
              public router2: ActivatedRoute,
              public translate: TranslateService,
              private createContactGQL: CreateContactGQL) {
    super(router, translate);
  }

  ngOnInit(): void {
    /*console.log(this.router2);*/
    this.router2.url.subscribe(result => {
      console.log( result[0]?.path);
    });
  }

  onSendContact() {
    const arrValidateFields = [
      {value: this.modelContact.name, text: 'Nome <br />'},
      {value: this.modelContact.email, text: 'E-mail <br />'},
      {value: this.modelContact.subject, text: 'Assunto <br />'},
      {value: this.modelContact.message, text: 'Mensagem <br />'}
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage('Atenção', `Preencha os campos obrigatórios: <br><br> ${stringError}`);
      return;
    }
    if (!this.isEMailValid(this.modelContact.email)) {
      return;
    }

    this.createContactGQL.mutate(this.modelContact).subscribe(
      () => {
        this.modelContact = {};
        super.showMessage('Sucesso!', 'Sua mensagem foi enviada, em breve entraremos em contato.', 'success');
      }, (err) => {
      }
    );
  }

  toggleMenu(route = null) {
    if (route != null) {
      this.router.navigate([route]);
      setTimeout(() => {
        let paramScroll = 0;
        switch (route) {
          case '/Ambiental' :
            paramScroll = $('.panel-table-custom:eq(0)').offset().top - 100;
            break;
          case '/Social' :
            paramScroll = $('.panel-table-custom:eq(1)').offset().top - 100;
            break;
          case '/Governanca' :
            paramScroll = $('.panel-table-custom:eq(2)').offset().top - 100;
            break;
        }
        $('html, body').stop().animate({scrollTop: paramScroll}, 1000);
      }, 500);
    }
  }
}
