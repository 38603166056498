<div class="ods-bg-section">
  <div class="container ods">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h1 class="ods_title">{{'odstitle'|translate}}</h1>
      </div>
      <div class="col-sm-6">
        <p class="ods_text" [innerHTML]="'oneofourinitiative'|translate">

        </p>
        <p class="ods_text" [innerHTML]="'weassumepublicaly'|translate">

        </p>
        <p class="ods_text" [innerHTML]="'tosupportourstrategy'|translate">

        </p>
      </div>
    </div>
    <h2 class="ods_subtitle my-5" [innerHTML]="'odsownerseuro'|translate"></h2>
    <div class="row">
      <div class="col-md-6 col-lg-4 ods_step ods_step-3">
        <div class="d-flex align-items-center mb-3" style="gap: 15px">
          <h3 class="ods_step_number">3</h3>
          <h4 class="ods_step_title" [innerHTML]="'healthandliving'|translate"></h4>
          <img src="assets/images/highlight-3.png" alt="highlight">
        </div>
        <p class="ods_step_text">{{'guaranteehealthandquality'|translate}}</p>
      </div>
      <div class="col-md-6 col-lg-4 ods_step ods_step-4 ">
        <div class="d-flex align-items-center mb-3" style="gap: 15px">
          <h3 class="ods_step_number">4</h3>
          <h4 class="ods_step_title" [innerHTML]="'qualityeducation'|translate"></h4>
          <img src="assets/images/highlight-4.png" alt="highlight">
        </div>
        <p class="ods_step_text">
          {{'guaranteeaccesstoinclusiveeducation'|translate}}

        </p>
      </div>
      <div class="col-md-6 col-lg-4 ods_step ods_step-8">
        <div class="d-flex align-items-center mb-3" style="gap: 15px">
          <h3 class="ods_step_number">8</h3>
          <h4 class="ods_step_title">{{'decentworkandeconomicgrownth'|translate}}</h4>
          <img src="assets/images/highlight-8.png" alt="highlight">
        </div>
        <p class="ods_step_text">
          {{'promoveeconomicinclusivegrowth'|translate}}

        </p>
      </div>
      <div class="col-md-6 col-lg-4 ods_step ods_step-12">
        <div class="d-flex align-items-center mb-3" style="gap: 15px">
          <h3 class="ods_step_number">12</h3>
          <h4 class="ods_step_title">{{'responsableconsumism'|translate}}</h4>
          <img src="assets/images/highlight-12.png" alt="highlight">
        </div>
        <p class="ods_step_text">{{'guaranteeconsumismpatterns'|translate}} </p>
      </div>
      <div class="col-md-6 col-lg-4 ods_step ods_step-13">
        <div class="d-flex align-items-center mb-3" style="gap: 15px">
          <h3 class="ods_step_number">13</h3>
          <h4 class="ods_step_title">{{'climatechangeaction'|translate}}</h4>
          <img src="assets/images/highlight-13.png" alt="highlight">
        </div>
        <p class="ods_step_text">{{'adopturgentways'|translate}}</p>
      </div>
      <div class="col-md-6 col-lg-4 ods_step d-flex align-items-center">
        <a href="{{getCulture()}}" target="_blank" class="ods_btn btn btn-outline-primary w-100">
          <span [innerHTML]="'checkthisandother'|translate"></span>
          <svg-icon src="assets/svg/arrowright.svg"></svg-icon>
        </a>
      </div>
    </div>
  </div>
</div>


<div class="ods-bg" style="background-color: #F8F9F9">
  <div class="container ods ods-gray">
    <img class="d-flex mx-auto" src="assets/images/icon-0.png" alt="objective">
    <h2 class="ods_title mt-4 mb-5 ods_title-h2 text-center">{{'globalpactprinciples'|translate}}</h2>
    <div class="row">
      <div class="col-auto">
        <div class="ods_objectives" style="gap: 37px">
          <div class="ods_objectives_item" *ngFor="let item of this.listSelect; let i = index;">
            <img class="ods_objectives_img" *ngIf="item.status" src="assets/images/icon-{{ item.id }}.png" alt="icon"/>
            <ng-container *ngIf="!item.status">
              <div class="d-none d-lg-flex align-items-center">
                <div class="ods_dashed">{{item.id}}</div>
                <svg-icon class="ods_objectives_icon" *ngIf="item.id != 13" src="assets/svg/dashed-ods.svg"></svg-icon>
                <svg-icon class="ods_objectives_icon" *ngIf="item.id == 13" src="assets/svg/dashed-ods-down.svg"></svg-icon>
              </div>

              <div class="ods_objectives_card ods_objectives_card-{{item.id}}">
                <img class="ods_objectives_card_img" src="assets/images/icon-{{ item.id }}.png" alt="icon"/>
                <p class="ods_objectives_card_text mb-0">{{item.text | translate}}</p>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
