<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="javascript:void(0)" (click)="onNavigator('/')">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Temas Materiais</li>
    </ol>
  </nav>
  <section class="page">
    <div class="row">
      <div class="col-12 col-lg-6">
        <h2 class="page__title">Temas <span>Materiais</span></h2>
        <p class="page__text">O processo contínuo de engajamento e diálogo contribui de maneira essencial com a
          estratégia de desenvolvimento sustentável do Grupo Eurofarma. Esse processo permite aperfeiçoar a atuação da
          empresa, definir prioridades e direcionar as ações de gestão e comunicação ESG (ambiental, social e
          governança), que considera uma análise de temas materiais para o setor farmacêutico no mundo.</p>
      </div>
      <div class="col-12 col-lg-6 d-flex justify-content-center align-items-end">
        <h3 class="page__information">Clique nos temas materiais <span>e confira os indicadores.</span></h3>
      </div>
    </div>
    <div class="mt-3 mt-md-5 mb-5 row">
      <article *ngFor="let m of materials" class="col-12 col-lg-6">
        <a href="javascript:void('')" class="post" (click)="onMaterialDetail(m)">  <!--data-toggle="modal" data-target="#filter"-->
          <h4 class="post__title">{{m.name}}</h4>
          <p>{{m.description}}</p>
          <svg-icon class="svg" src="assets/images/more.svg"></svg-icon>
        </a>
      </article>
    </div>
  </section>

  <!-- Modal -->
  <div id="filter" class="modal dark fade modal-to-up">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <span class="modal-title-type">
              <strong>{{modelFiltered?.name}}</strong>
              <span>{{modelFiltered?.description}}</span>
            </span>
          </h5>
          <span class="search">
            <form novalidate>
              <svg-icon class="svg" src="assets/images/search.svg"></svg-icon>
              <input class="placehoder-white" type="text" placeholder="Buscar" #search
                     (keyup)="onKey(search.value)" />
            </form>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </span>
        </div>
        <div class="modal-body">
          <div class="accordion_item_notfound d-none text-white">
            Nenhum resultado encontrado para <strong class="text-uppercase"></strong>, tente outro termo.
          </div>
          <ul class="modal-list js-filter-list">
            <li class="modal-material js-filter-list-item" *ngFor="let a of modelFiltered?.content">
              <a href="javascript:void(0)" (click)="onNavigator('/materiais-detalhe/' + modelFiltered.id)" data-dismiss="modal">
                <strong>{{a.gri}}-{{a.index}}</strong>
                <span class="material_span">{{a.title}}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
