import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {HomeComponent} from './views/home/home.component';
import {MateriaisComponent} from './views/materiais/materiais.component';
import {MateriaisDetalheComponent} from './views/materiais-detalhe/materiais-detalhe.component';
import {GriComponent} from './views/gri/gri.component';
import {OdsComponent} from './views/ods/ods.component';
import {GriDetalheComponent} from "./views/gri-detalhe/gri-detalhe.component";
import {GoalsComponent} from "./views/goals/goals.component";
import {GoalsDetailComponent} from "./views/goals-detail/goals-detail.component";

const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
      {path: '', component: HomeComponent},
      {path: 'materiais', component: MateriaisComponent},
      {path: 'materiais-detalhe/:id', component: MateriaisDetalheComponent},
      {path: 'gri', component: GriComponent},
      {path: 'metas', component: GoalsComponent},
      {path: 'metas-detalhe/:id', component: GoalsDetailComponent},
      {path: 'gri-detalhe/:id', component: GriDetalheComponent},
      {path: 'ods', component: OdsComponent},
      {path: ':id', component: HomeComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
