import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Apollo, gql, QueryRef} from 'apollo-angular';

import {Observable, Subscription} from "rxjs";
import {map} from "rxjs/operators";

import {
  Query,
  GetUsersGQL,
  CreateUserGQL,
  GetUsersQuery
} from "../../../generated/graphql"
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})

export class MainComponent extends BaseComponent implements OnInit {
  users: Observable<Query['getUsers']>;
  usersQuery: QueryRef<GetUsersQuery>;

  modelUser: any = {};

  languageUrl: any = null;

  constructor(public router: Router,
              private routeActive: ActivatedRoute,
              private apollo: Apollo,
              private getUsersGQL: GetUsersGQL,
              private createUserGQL: CreateUserGQL,
              public translate: TranslateService
  ) {
    super(router, translate);
  }

  ngOnInit(): void {
    this.usersQuery = this.getUsersGQL.watch();
    this.users = this.usersQuery.valueChanges.pipe(map(result => result.data.getUsers));

    this.routeActive.queryParams.subscribe(params => {
      this.languageUrl = params.language;
      if (this.isNullOrUndefined(this.languageUrl)) { return; }
    });

    // LOADING FORCE REMOVE
    setTimeout(() => {
      super.toggleLoader(false);
    }, 1000);
    this.handlerLanguage();
  }

  handlerLanguage() {
    console.log('handlerLanguage');
    console.log(this.languageUrl);
    if (!this.isNullOrUndefined(this.languageUrl)) {
      this.switchLanguage(this.languageUrl);
      return;
    }
    if (localStorage.getItem('culture') === 'pt-BR') {
      this.switchLanguage('pt');
    }
    if (localStorage.getItem('culture') === 'es-ES') {
      this.switchLanguage('es');
    }
    if (localStorage.getItem('culture') === 'en-US') {
      this.switchLanguage('en');
    }
    if (this.isNullOrUndefined(localStorage.getItem('culture'))) {
      localStorage.setItem('culture', 'pt-BR');
      super.switchLanguage('pt');
    }
  }

  onCreateUser(): void {
    if (
      this.isNullOrUndefined(this.modelUser.email)
      || this.isNullOrUndefined(this.modelUser.password)
    ) {
      this.showMessage('Atenção', 'Informe os campos para prosseguir.')
      return;
    }

    this.createUserGQL.mutate({
      name: this.modelUser.name,
      email: this.modelUser.email,
      // password: this.modelUser.password,
    }).subscribe(
      () => {
        this.modelUser = {};
        this.usersQuery.refetch();
      }, (err) => {
      }
    )
  }

}
