import {Component, OnInit} from '@angular/core';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

declare var $;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

  opened = false;

  constructor(public router: Router,
              public translate: TranslateService) {
    super(router, translate);
  }

  ngOnInit(): void {
  }

  toggleMenu(route = null) {
    if (route != null) {
      this.router.navigate([route]);
      setTimeout(() => {
        let paramScroll = 0;
        switch (route) {
          case '/Ambiental' :
            paramScroll = $('.panel-table-custom:eq(0)').offset().top - 100;
            break;
          case '/Social' :
            paramScroll = $('.panel-table-custom:eq(1)').offset().top - 100;
            break;
          case '/Governanca' :
            paramScroll = $('.panel-table-custom:eq(2)').offset().top - 100;
            break;
        }
        $('html, body').stop().animate({scrollTop: paramScroll}, 1000);
      }, 500);
    }
    this.opened = !this.opened;
  }

}
