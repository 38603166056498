import {Component, OnInit, AfterViewInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../service/user.service";
import {Meta, MetasGQL, MetasQuery} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";

declare var $;

class NgxSpinnerService {
}

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss']
})
export class GoalsComponent extends BaseComponent implements OnInit, AfterViewInit {

  model: Meta[] = [];
  modelQuery: QueryRef<MetasQuery>;

  constructor(public router: Router,
              public metasGQL: MetasGQL,
              public translate: TranslateService
  ) {
    super(router, translate);
  }

  ngOnInit(): void {
    this.getModel();
  }

  getModel() {
    this.modelQuery = this.metasGQL.watch();
    this.modelQuery.valueChanges.subscribe(({data}) => {
      this.model = data.metas as Meta[];
    });
  }

  ngAfterViewInit(): void {

  }

  collapseAction(i: any) {
    $('.collapse').collapse('hide');
    $('#collapse_' + i).collapse('toggle');
    $('#item_' + i).toggleClass('active');
  }
}
