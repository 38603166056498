<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Global Reporting Initiative (GRI)</li>
    </ol>
  </nav>
  <section class="page">
    <div class="row">
      <div class="col-12 col-lg-8">
        <h2 class="page__title">Global Reporting Initiative - <span>GRI</span></h2>
        <p class="page__text">O Grupo Eurofarma segue as diretrizes preconizadas pela Global Reporting Initiative (GRI), que estabelece as melhores práticas globais para divulgação dos aspectos econômicos, ambientais e sociais.</p>
      </div>
    </div>
    <div class="mt-3 mt-md-5 mb-5 d-flex flex-wrap justify-content-start">
      <article class="gri" *ngFor="let a of gris" [style]="{'border-left': '9px solid' + a.color}">
        <div href="#" class="gri__head">
          <h4 class="gri__title">{{a?.title}}</h4>
          <p class="gri__text">{{a?.description}}</p>
        </div>
        <div href="#" class="gri__tags">
          <span class="gri__tags__item" *ngFor="let sub of a?.groups" (click)="onGriDetail(a, sub)">
            {{sub.name}}
          </span>
        </div>
      </article>
      <!--<article class="gri cat-2">
        <div href="#" class="gri__head">
          <h4 class="gri__title">GRI 102</h4>
          <p class="gri__text">Combate à Corrupção</p>
        </div>
        <div href="#" class="gri__tags">
          <span class="gri__tags__item" data-toggle="modal" data-target="#filter">Combate à Corrupção</span>
        </div>
      </article>
      <article class="gri cat-2">
        <div href="#" class="gri__head">
          <h4 class="gri__title">GRI 102</h4>
          <p class="gri__text">Concorrência Desleal</p>
        </div>
        <div href="#" class="gri__tags">
          <span class="gri__tags__item" data-toggle="modal" data-target="#filter">Concorrência Desleal</span>
        </div>
      </article>
      <article class="gri cat-3">
        <div href="#" class="gri__head">
          <h4 class="gri__title">GRI 102</h4>
          <p class="gri__text">Eficiência Energética</p>
        </div>
        <div href="#" class="gri__tags">
          <span class="gri__tags__item" data-toggle="modal" data-target="#filter">Eficiência Energética</span>
        </div>
      </article>
      <article class="gri cat-3">
        <div href="#" class="gri__head">
          <h4 class="gri__title">GRI 102</h4>
          <p class="gri__text">Água</p>
        </div>
        <div href="#" class="gri__tags">
          <span class="gri__tags__item" data-toggle="modal" data-target="#filter">Eficiência Energética</span>
        </div>
      </article>
      <article class="gri cat-3">
        <div href="#" class="gri__head">
          <h4 class="gri__title">GRI 102</h4>
          <p class="gri__text">Mudança Climática</p>
        </div>
        <div href="#" class="gri__tags">
          <span class="gri__tags__item" data-toggle="modal" data-target="#filter">Mudança Climática</span>
        </div>
      </article>
      <article class="gri cat-3">
        <div href="#" class="gri__head">
          <h4 class="gri__title">GRI 102</h4>
          <p class="gri__text">Resíduos</p>
        </div>
        <div href="#" class="gri__tags">
          <span class="gri__tags__item" data-toggle="modal" data-target="#filter">Gestão de Resíduos <br>e Pós-consumo</span>
        </div>
      </article>
      <article class="gri cat-4">
        <div href="#" class="gri__head">
          <h4 class="gri__title">GRI 102</h4>
          <p class="gri__text">Saúde e Segurança
            do Trabalho</p>
        </div>
        <div href="#" class="gri__tags">
          <span class="gri__tags__item" data-toggle="modal" data-target="#filter">Saúde e Segurança
            do Trabalho</span>
        </div>
      </article>
      <article class="gri cat-4">
        <div href="#" class="gri__head">
          <h4 class="gri__title">GRI 102</h4>
          <p class="gri__text">Treinamento e Educação</p>
        </div>
        <div href="#" class="gri__tags">
          <span class="gri__tags__item" data-toggle="modal" data-target="#filter">Treinamento e Educação</span>
        </div>
      </article>
      <article class="gri cat-4">
        <div href="#" class="gri__head">
          <h4 class="gri__title">GRI 102</h4>
          <p class="gri__text">Comunidades</p>
        </div>
        <div href="#" class="gri__tags">
          <span class="gri__tags__item" data-toggle="modal" data-target="#filter">Relações com a Sociedade</span>
        </div>
      </article>-->
    </div>
  </section>
  <!-- Modal -->

  <!-- As classes cat-1,  cat-2 e cat-3 alteram a cor do modal -->
  <div id="filterGRI" class="modal dark fade modal-to-up">
    <div class="modal-dialog modal-lg">
      <div class="modal-content" [style]="{'background-color': modelFiltered?.color}">
        <div class="modal-header">
          <h5 class="modal-title">
            <span class="modal-title-type">
              <strong>{{modelFiltered?.title}}</strong>
              <span>{{modelFiltered?.description}}</span>
            </span>
            <span class="large">{{modelFilteredSon?.title}}</span>
          </h5>
          <span class="search">
            <form novalidate>
              <svg-icon class="svg" src="assets/images/search.svg"></svg-icon>
              <input type="text" placeholder="Buscar" #search
                     (keyup)="onKey(search.value)">
            </form>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </span>
        </div>
        <div class="modal-body">
          <ul class="modal-list js-filter-list">
            <li class="modal-material js-filter-list-item" *ngFor="let a of modelFilteredSon?.content">
              <a href="javascript:void(0)" (click)="onNavigator('/gri-detalhe/' + a.id)" data-dismiss="modal">
                <strong>{{a?.gri}}-{{a?.index}}</strong>
                <span>{{a?.title}}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>
