import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService extends ServiceBase {

  private sidebarDataSource = new BehaviorSubject<any>({});
  sidebarData = this.sidebarDataSource.asObservable();

  constructor(http: HttpClient) {
    super(http);
  }

  updateSideBarData(d: any) {
    this.sidebarDataSource.next(d);
  }

  getBank() {
    const url = `${this.BASE_URL}/bank/list-bank`;
    return this.http.get(url);
  }

  getCep(i) {
    const url = `https://viacep.com.br/ws/${i}/json`;
    return this.http.get(url);
  }


}
