<div class="content">
  <div class="hero" [ngStyle]="{
  'background-image': 'url(' + getCulture3() + ')',
  'background-repeat': 'no-repeat',
  'background-position': 'center 100px',
  'width': '100%',
  'height': '400px',
  'background-attachment': 'fixed'
}">
    <!--<img class="hero-banner" src="assets/images/hero.png" alt="">-->
  </div>
  <div class="description-center">
    <div class="description-center-content" [innerHTML]="'theindicatorscentral'|translate">

    </div>
  </div>

  <div class="panel-table-custom">
    <div class="panel-table-custom-header">
      <img  class="panel-table-custom-header-banner" src="{{getCulture()}}" alt="">
    </div>
    <div class="panel-table-custom-body d-flex">
      <div class="panel-table-custom-body-content d-flex">
        <div class="panel-table-custom-body-content-resume-card">
          <div class="panel-table-custom-body-content-resume-card-ball">
            <div class="panel-table-custom-body-content-resume-card-ball-content change-green">
              <span><strong>E</strong></span>
            </div>
          </div>
          <div class="panel-table-custom-body-content-resume-card-content">
            <span
              class="panel-table-custom-body-content-resume-card-content-text" [innerHTML]="'inourvision'|translate"></span>
          </div>
        </div>
        <div class="panel-table-custom-body-content-resume-btn">
          <div class="panel-table-custom-body-content-resume-btn-header">
            <span><strong>{{'knowmoreabout'|translate}}</strong></span>
          </div>
          <div class="panel-table-custom-body-content-resume-btn-body">
            <a href="https://eurofarma.com.br/responsabilidade-corporativa/meio-ambiente" target="_blank"
               class="btn-ambiental">
              <span><strong>{{'clickhere'|translate}}</strong></span>
              <svg-icon class="svg" src="assets/svg/arrowright.svg"></svg-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="style-table page-table">
      <div class="table-responsive">
        <table>

          <tbody>

          <tr class="head-table">
            <td class="top"><strong>{{'theme'|translate}}</strong></td>
            <td class="top"><strong>{{'indicator'|translate}}</strong></td>
            <td colspan="5" style="padding: 0">
              <table>
                <tr>
                  <td colspan="5" class="top center">
                    <div class="d-flex">
                      <svg-icon src="assets/svg/row-table-left-min.svg"></svg-icon>
                      <strong class="flex-grow-1">2023</strong>
                      <svg-icon src="assets/svg/row-table-right-min.svg"></svg-icon>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="top center change-table-blue"><strong>1Tri</strong></td>
                  <td class="top center change-table-blue"><strong>2Tri</strong></td>
                  <td class="top center change-table-blue"><strong>3Tri</strong></td>
                  <td class="top center change-table-blue"><strong>4Tri</strong></td>
                  <td class="top center change-table-blue"><strong>{{'condolidated'|translate}}</strong></td>
                </tr>
              </table>
            </td>
            <td colspan="2" style="padding: 0">
              <table>
                <tr>
                  <td colspan="2" class="top center">
                    <div class="d-flex">
                      <svg-icon src="assets/svg/row-table-left-min.svg"></svg-icon>
                      <strong class="flex-grow-1">2024</strong>
                      <svg-icon src="assets/svg/row-table-right-min.svg"></svg-icon>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="top center change-table-blue"><strong>1Tri</strong></td>
                  <td class="top center change-table-blue"><strong>2Tri</strong></td>
                </tr>
              </table>
            </td>
          </tr>

          </tbody>

          <tbody>

          <tr>
            <td>{{'moregreen'|translate}}</td>
            <td>{{'porcentageparticipation'|translate}}</td>
            <td align="center">10,2%</td>
            <td align="center">10,8%</td>
            <td align="center">9,5%</td>
            <td align="center">9%</td>
            <td align="center">10%</td>
            <td align="center">9%</td>
            <td align="center">10%</td>
          </tr>
          <tr>
            <td>{{'moregreen'|translate}}</td>
            <td>{{'soldunits'|translate}}</td>
            <td align="center">{{'6.067.893'|translate}}</td>
            <td align="center">{{'7.103.300'|translate}}</td>
            <td align="center">{{'6.409.137'|translate}}</td>
            <td align="center">{{'6.521.142'|translate}}</td>
            <td align="center">{{'26.218.782'|translate}}</td>
            <td align="center">{{'6.185.616'|translate}}</td>
            <td align="center">8.139.770</td>
          </tr>
          <tr>
            <td>{{'renewwater'|translate}}</td>
            <td>{{'percentagewaterreuse'|translate}}</td>
            <td align="center">29%</td>
            <td align="center">36%</td>
            <td align="center">40%</td>
            <td align="center">30%</td>
            <td align="center">26%</td>
            <td align="center">14%</td>
            <td align="center">13%</td>
          </tr>
          <tr>
            <td>{{'renewwater'|translate}}</td>
            <td [innerHTML]="'percentagereusetotal'|translate"></td>
            <td align="center">18%</td>
            <td align="center">19%</td>
            <td align="center">22%</td>
            <td align="center">25%</td>
            <td align="center">22%</td>
            <td align="center">11%</td>
            <td align="center">10%</td>
          </tr>
          <tr>
            <td>{{'water'|translate}}</td>
            <td>{{'consumptionfor1000'|translate}}</td>
            <td align="center">0,89</td>
            <td align="center">0,78</td>
            <td align="center">0,74</td>
            <td align="center">0,72</td>
            <td align="center">0,78</td>
            <td align="center">0,91</td>
            <td align="center">0,98</td>
          </tr>

          <tr>
            <td>{{'water'|translate}} </td>
            <td> {{'consulptionWaterUnit'|translate}}</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">1,09</td>
            <td align="center">1,01</td>
          </tr>
          <tr>
            <td>{{'energy'|translate}} </td>
            <td> {{'consulptionEnergyUnit'|translate}}</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">0,86</td>
            <td align="center">0,72</td>
          </tr>
          <tr>
            <td>{{'energy'|translate}} </td>
            <td>{{'consumptionfor1000b'|translate}}</td>
            <td align="center">0,77</td>
            <td align="center">0,69</td>
            <td align="center">0,67</td>
            <td align="center">0,79</td>
            <td align="center">0,73</td>
            <td align="center">0,82</td>
            <td align="center">0,73</td>
          </tr>
          <tr>
            <td>{{'residue'|translate}} </td>
            <td> {{'consulptionResidueUnit'|translate}}</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">3,17</td>
            <td align="center">2,58</td>
          </tr>


          <tr>
            <td>{{'residue'|translate}} </td>
            <td> {{'residuegeneration'|translate}}</td>
            <td align="center">2,03</td>
            <td align="center">2,05</td>
            <td align="center">3,22</td>
            <td align="center">2,77</td>
            <td align="center">2,51</td>
            <td align="center">3,34</td>
            <td align="center">3,58</td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="panel-table-custom">
    <div class="panel-table-custom-header">
      <img class="panel-table-custom-header-banner" src="assets/images/banner2.png" alt="">
    </div>
    <div class="panel-table-custom-body d-flex">
      <div class="panel-table-custom-body-content d-flex">
        <div class="panel-table-custom-body-content-resume-card">
          <div class="panel-table-custom-body-content-resume-card-ball">
            <div class="panel-table-custom-body-content-resume-card-ball-content change-orange">
              <span><strong>S</strong></span>
            </div>
          </div>
          <div class="panel-table-custom-body-content-resume-card-content">
          <span
            class="panel-table-custom-body-content-resume-card-content-text" [innerHTML]="'wehavethecommitment'|translate"></span>
          </div>
        </div>
        <div class="panel-table-custom-body-content-resume-btn">
          <div class="panel-table-custom-body-content-resume-btn-header">
            <span><strong>{{'knowmoreaboutourstrategy'|translate}} </strong></span>
          </div>
          <div class="panel-table-custom-body-content-resume-btn-body">
            <a href="https://eurofarma.com.br/responsabilidade-corporativa" target="_blank" class="btn-ambiental">
              <span><strong>{{'clickhere'|translate}}</strong></span>
              <svg-icon class="svg" src="assets/svg/arrowright.svg"></svg-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="style-table page-table">
      <div class="table-responsive">
        <table>

          <tbody>

          <tr class="head-table">
            <td class="top"><strong>{{'theme'|translate}}</strong></td>
            <td class="top"><strong>{{'indicator'|translate}}</strong></td>
            <td colspan="5" style="padding: 0">
              <table>
                <tr>
                  <td colspan="5" class="top center">
                    <div class="d-flex">
                      <svg-icon src="assets/svg/row-table-left-min.svg"></svg-icon>
                      <strong class="flex-grow-1">2023</strong>
                      <svg-icon src="assets/svg/row-table-right-min.svg"></svg-icon>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="top center change-table-blue"><strong>1Tri</strong></td>
                  <td class="top center change-table-blue"><strong>2Tri</strong></td>
                  <td class="top center change-table-blue"><strong>3Tri</strong></td>
                  <td class="top center change-table-blue"><strong>4Tri</strong></td>
                  <td class="top center change-table-blue"><strong>{{'consolidado'|translate}}</strong></td>
                </tr>
              </table>
            </td>
            <td colspan="2" style="padding: 0">
              <table>
                <tr>
                  <td colspan="2" class="top center">
                    <div class="d-flex">
                      <svg-icon src="assets/svg/row-table-left-min.svg"></svg-icon>
                      <strong class="flex-grow-1">2024</strong>
                      <svg-icon src="assets/svg/row-table-right-min.svg"></svg-icon>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="top center change-table-blue"><strong>1Tri</strong></td>
                  <td class="top center change-table-blue"><strong>2Tri</strong></td>
                </tr>
              </table>
            </td>
          </tr>

          </tbody>

          <tbody>

          <tr>
            <td>{{'inclusionanddiversity'|translate}}</td>
            <td>{{'percentageofwoman'|translate}}</td>
            <td align="center">29%</td>
            <td align="center">30%</td>
            <td align="center">30%</td>
            <td align="center">30%</td>
            <td align="center">30%</td>
            <td align="center">30%</td>
            <td align="center">31%</td>

          </tr>
          <tr>
            <td>{{'inclusionanddiversity'|translate}}</td>
            <td>{{'percentageofwomanhigh'|translate}}</td>
            <td align="center">52%</td>
            <td align="center">52%</td>
            <td align="center">52%</td>
            <td align="center">52%</td>
            <td align="center">52%</td>
            <td align="center">50%</td>
            <td align="center">51%</td>

          </tr>
          <tr>
            <td>{{'inclusionanddiversity'|translate}}</td>
            <td>{{'percentageofwomanselling'|translate}}</td>
            <td align="center">57%</td>
            <td align="center">56%</td>
            <td align="center">67%</td>
            <td align="center">56%</td>
            <td align="center">56%</td>
            <td align="center">61%</td>
            <td align="center">66%</td>
          </tr>
          <tr>
            <td>{{'inclusionanddiversity'|translate}}</td>
            <td>{{'percentageofwomanoperation'|translate}}</td>
            <td align="center">42%</td>
            <td align="center">37%</td>
            <td align="center">50%</td>
            <td align="center">41%</td>
            <td align="center">41%</td>
            <td align="center">-</td>
            <td align="center">47%</td>
          </tr>
          <tr>
            <td>{{'socialprivateinvestmemt'|translate}}</td>
            <td>{{'totalinvested'|translate}}</td>
            <td align="center">{{'9.881.136'|translate}}</td>
            <td align="center">{{'10.838.281'|translate}}</td>
            <td align="center">{{'13.433.455.41'|translate}}</td>
            <td align="center">{{'13.550.985.00'|translate}}</td>
            <td align="center">{{'49.962.283.00'|translate}}</td>
            <td align="center">-</td>
            <td align="center">40.150.520,46</td>
          </tr>

          <tr>
            <td>{{'socialprivateinvestmemt'|translate}}</td>
            <td>{{'percentageinvestmentsocial'|translate}}</td>
            <td align="center">2,0%</td>
            <td align="center">51,2%</td>
            <td align="center">6,3%</td>
            <td align="center">3,8%</td>
            <td align="center">4,4%</td>
            <td align="center">3,4%</td>
            <td align="center">3,9%</td>

          </tr>

          <tr>
            <td>{{'socialprivateinvestmemt'|translate}}</td>
            <td>{{'quantityofmedicationgiven'|translate}}</td>
            <td align="center">{{'69.639'|translate}}</td>
            <td align="center">{{'61.287'|translate}}</td>
            <td align="center">{{'233.212'|translate}}</td>
            <td align="center">{{'48.876.00'|translate}}</td>
            <td align="center">{{'413.014'|translate}}</td>
            <td align="center">{{'342.883'|translate}}</td>
            <td align="center">514.357</td>
          </tr>

          <tr>
            <td>{{'socialprivateinvestmemt'|translate}} </td>
            <td>{{'quantityofbasic'|translate}}</td>
            <td align="center">{{'47.616'|translate}}</td>
            <td align="center">{{'47.154'|translate}}</td>
            <td align="center">{{'47.441'|translate}}</td>
            <td align="center">{{'47.531'|translate}}</td>
            <td align="center">{{'189.742'|translate}}</td>
            <td align="center">{{'47.535'|translate}}</td>
            <td align="center">49.335</td>
          </tr>

          <tr>
            <td>{{'pbsf'|translate}}</td>
            <td>{{'numberofbabies'|translate}}</td>
            <td align="center">77</td>
            <td align="center">82</td>
            <td align="center">106</td>
            <td align="center">122</td>
            <td align="center">387</td>
            <td align="center">140</td>
            <td align="center">133</td>
          </tr>

          <tr>
            <td>{{'pbsf'|translate}}</td>
            <td>{{'numberofhoursmonitored'|translate}}</td>
            <td align="center">{{'5.160'|translate}}</td>
            <td align="center">{{'5.309'|translate}}</td>
            <td align="center">-</td>
            <td align="center">{{'7.660'|translate}}</td>
            <td align="center">{{'24.007'|translate}}</td>
            <td align="center">9.802</td>
            <td align="center">8.957</td>
          </tr>

          <tr>
            <td>{{'humanmilkbank'|translate}}</td>
            <td>{{'numberofbabiesattended'|translate}}</td>
            <td align="center">223</td>
            <td align="center">239</td>
            <td align="center">296</td>
            <td align="center">306</td>
            <td align="center">{{'1.064'|translate}}</td>
            <td align="center">-</td>
            <td align="center">215</td>
          </tr>

          <tr>
            <td>{{'humanmilkbank'|translate}}</td>
            <td>{{'donorsquantity'|translate}}</td>
            <td align="center">727</td>
            <td align="center">826</td>
            <td align="center">828</td>
            <td align="center">951</td>
            <td align="center">{{'3.332'}}</td>
            <td align="center">941</td>
            <td align="center">965</td>
          </tr>

          <tr>
            <td>{{'humanmilkbank'|translate}}</td>
            <td>{{'totalInvestimentLactare'|translate}}</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">1.089.910</td>
            <td align="center">1.020.289</td>
          </tr>

          <tr>
            <td>{{'eurofarmainstitute'|translate}}</td>
            <td>{{'totalinvestment'|translate}}</td>
            <td align="center">{{'4,135,000'|translate}}</td>
            <td align="center">{{'6,908,678'|translate}}</td>
            <td align="center">{{'4,550,000'|translate}}</td>
            <td align="center">{{'5.785.000'|translate}}</td>
            <td align="center">{{'21.378.678'|translate}}</td>
            <td align="center">{{'5.205.000'|translate}}</td>
            <td align="center">7.005.000</td>
          </tr>

          <tr>
            <td>{{'eurofarmainstitute'|translate}}</td>
            <td>{{'totalyoungattended'|translate}}</td>
            <td align="center">{{'5.141'|translate}}</td>
            <td align="center">{{'3.682'|translate}}</td>
            <td align="center">-</td>
            <td align="center">{{'1.844'|translate}}</td>
            <td align="center">{{'18.794'|translate}}</td>
            <td align="center">{{'8.168'|translate}}</td>
            <td align="center">6.178</td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="panel-table-custom">
    <div class="panel-table-custom-header">
      <img class="panel-table-custom-header-banner" src="{{getCulture2()}}" alt="">
    </div>
    <div class="panel-table-custom-body d-flex">
      <div class="panel-table-custom-body-content d-flex">
        <div class="panel-table-custom-body-content-resume-card">
          <div class="panel-table-custom-body-content-resume-card-ball">
            <div class="panel-table-custom-body-content-resume-card-ball-content">
              <span><strong>G</strong></span>
            </div>
          </div>
          <div class="panel-table-custom-body-content-resume-card-content">
          <span
            class="panel-table-custom-body-content-resume-card-content-text" [innerHTML]="'wefollowrigorous'|translate"></span>

          </div>
        </div>
        <div class="panel-table-custom-body-content-resume-btn">
          <div class="panel-table-custom-body-content-resume-btn-header">
            <span><strong>{{'knowmoreaboutourgoverning'|translate}}</strong></span>
          </div>
          <div class="panel-table-custom-body-content-resume-btn-body">
            <a href="https://eurofarma.com.br/governanca-corporativa" target="_blank" class="btn-ambiental">
              <span><strong>{{'clickhere'|translate}}</strong></span>
              <svg-icon class="svg" src="assets/svg/arrowright.svg"></svg-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="style-table page-table">
      <div class="table-responsive">
        <table>

          <tbody>

          <tr class="head-table">
            <td class="top"><strong>{{'theme'|translate}}</strong></td>
            <td class="top"><strong>{{'indicator'|translate}}</strong></td>

            <td colspan="5" style="padding: 0">
              <table>
                <tr>
                  <td colspan="6" class="top center">
                    <div class="d-flex">
                      <svg-icon src="assets/svg/row-table-left-min.svg"></svg-icon>
                      <strong class="flex-grow-1">2023</strong>
                      <svg-icon src="assets/svg/row-table-right-min.svg"></svg-icon>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="top center change-table-blue"><strong>1Tri</strong></td>
                  <td class="top center change-table-blue"><strong>2Tri</strong></td>
                  <td class="top center change-table-blue"><strong>3Tri</strong></td>
                  <td class="top center change-table-blue"><strong>4Tri</strong></td>
                  <td class="top center change-table-blue"><strong>{{'condolidated'|translate}}</strong></td>
                </tr>
              </table>
            </td>
            <td colspan="2" style="padding: 0">
              <table>
                <tr>
                  <td colspan="2" class="top center">
                    <div class="d-flex">
                      <svg-icon src="assets/svg/row-table-left-min.svg"></svg-icon>
                      <strong class="flex-grow-1">2024</strong>
                      <svg-icon src="assets/svg/row-table-right-min.svg"></svg-icon>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="top center change-table-blue"><strong>1Tri</strong></td>
                  <td class="top center change-table-blue"><strong>2Tri</strong></td>
                </tr>
              </table>
            </td>
          </tr>

          </tbody>

          <tbody>

          <tr>
            <td>{{'chainofsupriments'|translate}}</td>
            <td>{{'localdistributors'|translate}}</td>
            <td align="center">94%</td>
            <td align="center">94%</td>
            <td align="center">94%</td>
            <td align="center">75%</td>
            <td align="center">63%</td>
            <td align="center">95%</td>
            <td align="center">64%</td>
          </tr>

          <tr>
            <td>{{'chainofsupriments'|translate}}</td>
            <td>{{'localdistributorsin'|translate}}</td>
            <td align="center">93%</td>
            <td align="center">93%</td>
            <td align="center">93%</td>
            <td align="center">93%</td>
            <td align="center">93%</td>
            <td align="center">92%</td>
            <td align="center">93%</td>
          </tr>

          <tr>
            <td>{{'suppliersmonitorement'|translate}}</td>
            <td>{{'esgaudit'|translate}}</td>
            <td align="center">31</td>
            <td align="center">50</td>
            <td align="center">61</td>
            <td align="center">61</td>
            <td align="center">203</td>
            <td align="center">48</td>
            <td align="center">45</td>

          </tr>

<!--          <tr>-->
<!--            <td>Reputação</td>-->
<!--            <td>Posistividade na Mídia</td>-->
<!--            <td align="center">100%</td>-->
<!--            <td align="center">87%</td>-->
<!--            <td align="center">100%</td>-->
<!--            <td align="center">99%</td>-->
<!--            <td align="center">97%</td>-->
<!--            <td align="center">100%</td>-->
<!--            <td align="center">99,8%</td>-->
<!--            <td align="center">15%</td>-->
<!--          </tr>-->

          <tr>
            <td>{{'consummersatisfaction'|translate}}</td>
            <td>{{'consummersatisfactionlevelHealthProfessional'|translate}} </td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">84%</td>
            <td align="center">81%</td>
          </tr>

          <tr>
            <td>{{'consummersatisfaction'|translate}}</td>
            <td>{{'consummersatisfactionlevel'|translate}} </td>
            <td align="center">Anual</td>
            <td align="center">Anual</td>
            <td align="center">Anual</td>
            <td align="center">Anual</td>
            <td align="center">91%</td>
            <td align="center">84%</td>
            <td align="center">-</td>
          </tr>

<!--          <tr>-->
<!--            <td>Satisfação Clientes</td>-->
<!--            <td>% Nível de Serviço para principais clientes</td>-->
<!--            <td align="center">-</td>-->
<!--            <td align="center">90%</td>-->
<!--            <td align="center">-</td>-->
<!--            <td align="center">-</td>-->
<!--            <td align="center">66%</td>-->
<!--            <td align="center">78%</td>-->
<!--            <td align="center">86%</td>-->
<!--            <td align="center">-4%</td>-->
<!--          </tr>-->

          <tr>
            <td>{{'workerssatisfaction'|translate}}</td>
            <td>{{'GPTW'|translate}}</td>
            <td align="center">{{'stillunavailable'|translate}}</td>
            <td align="center">{{'stillunavailable'|translate}}</td>
            <td align="center">93%</td>
            <td align="center">93%</td>
            <td align="center">93%</td>
            <td align="center">Apuração apenas no final do ano</td>
            <td align="center">Apuração apenas no final do ano</td>
          </tr>

          <!--<tr>
            <td>Compliance</td>
            <td>{{'preceedingocurrences'|translate}}</td>
            <td align="center">14.12%</td>
            <td align="center">15.86%</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
          </tr>-->


          <tr>
            <td>{{'compliance'|translate}}</td>
            <td>{{'actionTrainingEmployee'|translate}}</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">8</td>
            <td align="center">27</td>
          </tr>
          <tr>
            <td>{{'compliance'|translate}}</td>
            <td>{{'actionTrainingThird'|translate}}</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">3</td>
            <td align="center">21</td>
          </tr>
          <tr>
            <td>{{'compliance'|translate}}</td>
            <td>{{'actionCommunication'|translate}}</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">-</td>
            <td align="center">4</td>
            <td align="center">8</td>
          </tr>


          <tr>
            <td>{{'compliance'|translate}}</td>
            <td>{{'numberoflayoffs'|translate}}</td>
            <td align="center">3</td>
            <td align="center">6</td>
            <td align="center">6</td>
            <td align="center">4</td>
            <td align="center">15</td>
            <td align="center">7</td>
            <td align="center">35</td>
          </tr>

          <tr>
            <td>{{'competitivness'|translate}}</td>
            <td>{{'latamposition'|translate}}</td>
            <td align="center">Top 3</td>
            <td align="center">Top 3</td>
            <td align="center">Top 3</td>
            <td align="center">Top 3</td>
            <td align="center">Top 3</td>
            <td align="center">2a posição</td>
            <td align="center">2a posição</td>

          </tr>

          <tr>
            <td>{{'competitivness'|translate}}</td>
            <td>Market Share Varejo BR</td>
            <td align="center">6,42%</td>
            <td align="center">6,31%</td>
            <td align="center">6,41%</td>
            <td align="center">6,39%</td>
            <td align="center">6,33%</td>
            <td align="center">6,38%</td>
            <td align="center">6,58%</td>

          </tr>

          <tr>
            <td>{{'competitivness'|translate}}</td>
            <td>Market Share Lançamentos (IQVIA ult 24m) BR</td>
            <td align="center">8,39%</td>
            <td align="center">10,40%</td>
            <td align="center">9,69%</td>
            <td align="center">8,14%</td>
            <td align="center">9,23%</td>
            <td align="center">6,13%</td>
            <td align="center">6,48%</td>
          </tr>

          <tr>
            <td>{{'innovationatpd'|translate}}</td>
            <td>{{'incrementalprojects'|translate}}</td>
            <td align="center">23,40%</td>
            <td align="center">21,50%</td>
            <td align="center">24,70%</td>
            <td align="center">25,30%</td>
            <td align="center">25,30%</td>
            <td align="center">-</td>
            <td align="center">-</td>
          </tr>

          <tr>
            <td>{{'openinovation'|translate}}</td>
            <td>{{'numberofatacks'|translate}}</td>
            <td align="center">9</td>
            <td align="center">9</td>
            <td align="center">9</td>
            <td align="center">10</td>
            <td align="center">10</td>
            <td align="center">10</td>
            <td align="center">10</td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>


<ng-container *ngIf="false">
  <section class="banners">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="primarySlider">
      <ng-container *ngFor="let b of banners">
        <div ngxSlickItem class="slide" *ngIf="b.type === 'banner'"
             [style]="{'background-size': 'cover', 'background-image': 'url('+ b.bannerUrl +')'}">
          <div class="container">
            <h2 class="banners__title" [innerHtml]="b.title"></h2>
            <p class="banners__description" [innerHtml]="b.description"></p>
            <img src="/assets/images/seal.png" alt="" width="100%">
          </div>
        </div>
      </ng-container>
    </ngx-slick-carousel>
    <svg-icon class="banners__down svg" src="assets/images/scroll.svg"></svg-icon>
  </section>

  <section class="shortcut">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 shortcut__head">
          <h2 class="shortcut__title" [innerHTML]="'indicatorscentral'|translate"></h2>
        </div>
        <div class="col-12 col-md-6">
          <p [innerHTML]="'indicatorscentralpresents'|translate"></p>
          <p [innerHTML]="'usethesuperiormenu'|translate"></p>
        </div>
      </div>
    </div>
  </section>

  <ng-container *ngFor="let b of banners">
    <section class="sustentability" *ngIf="b.type === 'background'"
             [style]="{'background-size': 'cover', 'background-image': 'url('+ b.bannerUrl +')'}">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-5">
            <h2 class="sustentability__title">{{'sustainableprimer'|translate}}</h2>
            <p class="page__text"> {{'checkthemainactions'|translate}}</p>
            <a href="#" class="page__btn btn btn-outline-primary">
              <span>{{'downloadhere'|translate}}</span>
              <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
            </a>
          </div>
        </div>
      </div>
    </section>
  </ng-container>

  <ng-container>
    <div *ngFor="let b of highlights; let i = index;">
      <div class="central__slide"> <!--cat-3-->
        <h3 *ngIf="!isNullOrUndefined(b.itens)" class="central__title">
          <img [src]="b.icon" alt=""/>
          <span [style]="{'color': b.color}">{{b.name}}</span>
        </h3>


        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="thirdSlider">
          <div *ngFor="let x of b.itens" ngxSlickItem>
            <div class="slide" class="central__item" [style]="{'background-color': b.color}">
              <h4 class="central__item__title">
                <span>{{x.title}}</span>
                <!--<small> {{x.title}}</small>-->
              </h4>
              <p class="central__item__text">{{x.description}}</p>
            </div>
          </div>
        </ngx-slick-carousel>


      </div>
    </div>
  </ng-container>

  <section class="central page js-counter-up d-none">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-8">
          <h2 class="page__title" [innerHTML]="'indicatorscentral'|translate"></h2>
          <p class="page__text" [innerHTML]="'theindicatorscentralpresents'|translate"></p>
          <br>
          <p class="page__text" [innerHTML]="'usethesuperiormenu'|translate"></p>
        </div>
      </div>
    </div>


    <!--<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="secondarySlider">
      <div ngxSlickItem class="slide" *ngFor="let b of highlights; let i = index;">
        <div class="central__slide" [style]="{'color': b.color}"> &lt;!&ndash;cat-3&ndash;&gt;
          <h3 class="central__title">
            <img [src]="b.imageUrl" alt="" />
            <span>{{b.type}}</span>
          </h3>
          <div class="central__item">
            <h4 class="central__item__title">
              <span [countUp]="highlightsNumbers[i].number01" [options]="options">0</span>
              <small> {{b.title01}}</small></h4>
            <p class="central__item__text">{{b.description01}}</p>
          </div>
          <hr>
          <div class="central__item">
            <h4 class="central__item__title">R$  <span [countUp]="highlightsNumbers[i].number02" [options]="options"
            >0</span> <small>{{b.title02}}</small></h4>
            <p class="central__item__text">{{b.description02}}</p>
          </div>
          <hr>
          <div class="central__item">
            <h4 class="central__item__title">{{b.title03}} <span [countUp]="highlightsNumbers[i].number03">0</span>%</h4>
            <p class="central__item__text">{{b.description03}}</p>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>-->
  </section>
</ng-container>
