import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgxMaskModule} from 'ngx-mask';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HeaderComponent} from './views/header/header.component';
import {FooterComponent} from './views/footer/footer.component';
import {HomeComponent} from './views/home/home.component';
import {LoginComponent} from './views/login/login.component';
import {RecaptchaModule} from 'angular-google-recaptcha';
import {MateriaisComponent} from './views/materiais/materiais.component';
import {MateriaisDetalheComponent} from './views/materiais-detalhe/materiais-detalhe.component';
import {GriDetalheComponent} from './views/gri-detalhe/gri-detalhe.component';
import {GriComponent} from './views/gri/gri.component';
import {OdsComponent} from './views/ods/ods.component';
import {GraphQLModule} from './graphql.module';
import {CountUpModule} from 'ngx-countup';
import { GoalsComponent } from './views/goals/goals.component';
import { GoalsDetailComponent } from './views/goals-detail/goals-detail.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HomeComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MateriaisComponent,
    MateriaisDetalheComponent,
    GriDetalheComponent,
    GriComponent,
    OdsComponent,
    GoalsComponent,
    GoalsDetailComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    CurrencyMaskModule,
    SlickCarouselModule,
    ReactiveFormsModule,
    RecaptchaModule.forRoot({
      siteKey: '6Lf5xoEcAAAAAOmsoNCcR-lXOXHorPiV835x0cIg',
    }),
    AngularSvgIconModule.forRoot(),
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    GraphQLModule,
    CountUpModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
