import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {QueryRef} from "apollo-angular";
import {
  ContentGQL, ContentQuery,
  GetGlobalReportingItemGQL,
  GetGlobalReportingItemQuery, GetGlobalReportsGQL, GetGlobalReportsQuery,
  GetMaterialsGQL,
  GetMaterialsQuery, Gri, GrisGQL, GrisQuery
} from "../../../generated/graphql";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";

declare var $;

@Component({
  selector: 'app-gri-detalhe',
  templateUrl: './gri-detalhe.component.html',
  styleUrls: ['./gri-detalhe.component.scss']
})
export class GriDetalheComponent extends BaseComponent implements OnInit, OnDestroy {
  opened = false;
  paramId = null;
  gri: any; // Observable<Query['getChallenges']>;
  griQuery: QueryRef<ContentQuery>;

  gris: Gri[] = [];
  grisQuery: QueryRef<GrisQuery>;

  modelSidebar: any = {};

  constructor(public router: Router,
              public route2: ActivatedRoute,
              public userService: UserService,
              private getGlobalReportsGQL: GetGlobalReportsGQL,
              private getGlobalReportingItemGQL: GetGlobalReportingItemGQL,
              private grisGQL: GrisGQL,
              private contentGQL: ContentGQL,
              private getMaterialsGQL: GetMaterialsGQL,
              public translate: TranslateService) {
    super(router, translate);
  }

  ngOnInit(): void {
    // tslint:disable-next-line:only-arrow-functions
    $(function() {
      $('.footer').find( '.container' ).addClass('footer_padding');
      $('#griMenu').addClass('active');
    });
    this.grisQuery = this.grisGQL.watch();
    this.grisQuery.valueChanges.subscribe(({data}) => {
      this.gris = data.gris as Gri[];
    });

    this.route2.params.subscribe(url => {
      if (!this.isNullOrUndefined(url)) {
        this.paramId = url.id;
        this.griQuery = this.contentGQL.watch({id: url.id});
        this.griQuery.valueChanges.subscribe(({data}) => {
          this.gri = data.content;
          setTimeout(() =>{
            this.handlerSideBarActive();
          });
        });
      }
    });
  }

  ngOnDestroy(): void {
    $('.footer').find( '.container' ).removeClass('footer_padding');
    $('#griMenu').removeClass('active');
  }

  handlerSideBarActive() {
    $('.js-filter-sidebar-subitem').removeClass('active');
    const a = $('.js-filter-sidebar-subitem[data-id='+ this.paramId +']');
    a.addClass('active').closest('.collapse').parent().find('a:eq(0)').addClass('active');
    a.closest('.collapse').collapse('show');
    a.closest('.collapse').closest('.collapse').collapse('show');
    a.closest('.js-filter-sidebar-item').find('a:eq(0)').addClass('active');
  }

  openSidebar() {
    this.opened = !this.opened;
  }

  collapseAction(id) {
    console.log(id);
    $(`#collapsePrimary${id}`).collapse('toggle');
    $(`#collapseItem${id}`).toggleClass('active');
  }

  collapseActionInternal(sub) {
    $(`#collapseOneIntern${sub}`).collapse('toggle');
    $(`#collapseItemInternal${sub}`).toggleClass('active');
  }

  onNavigator(s: string) {
    $('html, body').stop().animate({scrollTop: 0}, 1000);
    this.router.navigate([s]);
  }


}
