import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, DefaultOptions, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {onError} from '@apollo/client/link/error';
import {environment} from '../environments/environment';
import Swal from 'sweetalert2';


const uri = environment.base_graphql; // <-- add the URL of the GraphQL server here

const error = onError(({graphQLErrors, networkError}) => { // need help on linking this with graphql module
  console.log('networkError');
  console.log(networkError);
  if (graphQLErrors) {
    if (graphQLErrors[0]?.extensions?.response?.statusCode === 401) {
      window.location.href = '/logout';
    } else if (graphQLErrors[0].message) {
      Swal.fire({
        title: 'Atenção',
        html: graphQLErrors[0].message,
        icon: 'warning',
        confirmButtonColor: '#032E58',
      });
    }
    graphQLErrors.map(({message, locations, path}) => {
        Swal.fire({
          title: 'Atenção',
          html: message,
          icon: 'warning',
          confirmButtonColor: '#032E58',
        });
        // console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      },
    );
  }
  if (networkError) {
    const e: any = networkError;
    if (e.status === 401) {
      // @ts-ignore
      this.route.navigate(['/']);
    }
    if (e.status === 400) {
      let str = '';
      e.error.errors.map((x) => {
        if (x.message.length > 0) {
          str += x.message;
        }
      });
      alert(str);
    }
    // console.log(networkError);
  }
});

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  return {
    link: error.concat(httpLink.create({uri})), // httpLink.create({uri}),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {
}
